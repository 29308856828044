import { Dialog } from "@app/components/dialog";

import checkedSrc from "./checked.svg";
import uncheckedSrc from "./unchecked.svg";

import { Button } from "@app/components/button";
import { FiArrowDown, FiArrowUp } from "react-icons/fi";
import styles from "./index.module.css";

const DEFAULT_SORT = "transaction_date";
const SORT_FIELDS = [
	{
		value: "transaction_date",
		label: "Date",
	},
	{
		value: "recipient_name",
		label: "Recipient",
	},
	{
		value: "zar_amount",
		label: "ZAR Amount",
	},
	{
		value: "direction",
		label: "Type",
	},
	{
		value: "fx_amount",
		label: "FX Amount",
	},
];

const SORT_ORDER = [
	{
		value: 1,
		label: "Ascending",
	},
	{
		value: -1,
		label: "Descending",
	},
];

export const SortTransactionsModal = ({
	onClose,
	onSort,
	sortField,
	sortOrder,
}: {
	onClose: () => void;
	sortField?: string;
	sortOrder?: number;
	onSort: (sortOrder: number, sortField: string) => void;
}) => {
	return (
		<Dialog
			title="Sort Transactions"
			isOpen
			fullscreen
			onClose={onClose}
			actions={<Button onClick={onClose}>Done</Button>}
		>
			<ul>
				{SORT_FIELDS.map((field) => {
					const isActive = field.value === sortField;
					return (
						<li key={field.value}>
							<button
								className={styles.item}
								type="button"
								onClick={() => onSort(sortOrder ?? 1, field.value)}
								data-active={isActive}
							>
								<p>
									{field.label}{" "}
									{field.value === DEFAULT_SORT ? (
										<span className={styles.default}>(default)</span>
									) : null}
								</p>
								<img
									src={isActive ? checkedSrc : uncheckedSrc}
									alt=""
									width={16}
									height={16}
								/>
							</button>
						</li>
					);
				})}
			</ul>
			<div className={styles.divider} />
			<ul>
				{SORT_ORDER.map((order) => {
					const isActive = order.value === sortOrder;
					const color = isActive ? "#56A7A2" : "#B0B0B0";
					return (
						<li key={order.value}>
							<button
								className={styles.item}
								type="button"
								onClick={() => onSort(order.value, sortField ?? DEFAULT_SORT)}
								data-active={isActive}
							>
								{order.label}
								<div className={styles.icons}>
									{order.value === 1 ? (
										<FiArrowUp size={20} color={color} />
									) : (
										<FiArrowDown size={20} color={color} />
									)}
									<img
										src={isActive ? checkedSrc : uncheckedSrc}
										alt=""
										width={16}
										height={16}
									/>
								</div>
							</button>
						</li>
					);
				})}
			</ul>
		</Dialog>
	);
};
