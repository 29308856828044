import { combineReducers } from "redux";

import paymentsReducer from "./payments/payments-slice";
import recipientsReducer from "./recipients/recipients-slice";
import toastReducer from "./toast/toast-slice";
import transactionsReducer from "./transactions/transactions-slice";
import withdrawalsReducer from "./withdrawals/withdrawals-slice";

const combinedReducers = combineReducers({
	payments: paymentsReducer,
	recipients: recipientsReducer,
	toast: toastReducer,
	transactions: transactionsReducer,
	withdrawals: withdrawalsReducer,
});

export type RootState = ReturnType<typeof combinedReducers>;

const resetExclusions: string[] = [];

/**
 * This completely resets the redux store (typically used when logging out.)
 */
const rootReducer = (state: any, action: any) => {
	if (action.type === "RESET") {
		const newState: { [key: string]: any } = {};

		Object.keys(state).forEach((x) => {
			if (resetExclusions.includes(x)) newState[x] = state[x];
		});

		state = newState;
	}
	return combinedReducers(state, action);
};

export default rootReducer;
