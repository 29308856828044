import { forwardRef, useEffect, useRef, useState } from "react";

import { Button } from "@app/components/button";

import { Dialog } from "@app/components/dialog";
import type { GetBopDetails } from "@app/entities";
import styles from "./index.module.css";

import { Search } from "@app/components/search";
import { SingleSelectDialog } from "@app/components/single-select-dialog";

const Item = forwardRef<
	HTMLLIElement,
	{
		onClick: () => void;
		data: GetBopDetails;
		selected?: number;
	}
>(({ data, selected, onClick }, ref) => {
	return (
		<li
			ref={ref}
			className={styles.listItem}
			id={`bop-${data.id}-${data.bopCode}`}
		>
			<button
				className={styles.item}
				data-selected={selected === data.id}
				type="button"
				onClick={onClick}
			>
				<p className={styles.code}>{data.bopCode}</p>
				<p className={styles.category}>{data.category}</p>
				<p>{data.description}</p>
				{selected === data.id && <p className={styles.selected}>Selected</p>}
			</button>
		</li>
	);
});

const getCategoryParts = (category: string) => {
	const spaceIndex = category.indexOf(" ");
	return {
		code: category.slice(0, spaceIndex).trim(),
		description: category.slice(spaceIndex + 1).trim(),
	};
};

const CategoryLabel = ({ value }: { value: string }) => {
	const { code, description } = getCategoryParts(value);
	return (
		<p className={styles.categoryLabel}>
			<strong className={styles.categoryCode}>{code}</strong>
			&nbsp;&nbsp;
			{description}
		</p>
	);
};

export const FindBopCode = ({
	current,
	category,
	data,
	categoryOptions,
	onChangeCategory,
	search,
	onSearchChange,
	allowDeselect = true,
	onChangeSelected,
}: {
	current: GetBopDetails | undefined;
	data: GetBopDetails[] | undefined;
	category?: string;
	categoryOptions?: string[];
	onChangeCategory: (value: string | undefined) => void;
	search?: string;
	selected?: number;
	onChangeSelected: (id: number | undefined, bop?: GetBopDetails) => void;
	allowDeselect?: boolean;
	onSearchChange: (
		search?: string | undefined,
		immediateSearch?: boolean | undefined,
	) => void;
}) => {
	const [showConfirmDeselect, setShowConfirmDeselect] = useState(false);
	const [activeItem, setActiveItem] = useState<GetBopDetails | undefined>();
	const [showDialog, setShowDialog] = useState(false);

	const refs = useRef<Record<string, HTMLLIElement>>({});

	useEffect(() => {
		if (showDialog) {
			setActiveItem(current);
		}
	}, [showDialog, current]);

	useEffect(() => {
		if (current && showDialog) {
			const timeout = setTimeout(() => {
				const key = `bop-${current.id}-${current.bopCode}`;
				const ref = refs.current[key];
				if (ref) {
					ref.scrollIntoView({
						behavior: "smooth",
						block: "center",
					});
				}
			}, 300);
			return () => {
				clearTimeout(timeout);
			};
		}
	}, [current, showDialog]);

	return (
		<>
			{current && (
				<Item
					data={current}
					selected={current.id}
					onClick={() => {
						if (allowDeselect) {
							setShowConfirmDeselect(true);
						}
					}}
				/>
			)}
			<Button
				className={styles.button}
				variant="secondary"
				onClick={() => setShowDialog(true)}
			>
				Find BoP code
			</Button>
			<Dialog
				fullscreen
				showTopbar
				isOpen={showDialog}
				onClose={() => setShowDialog(false)}
				title="Find BoP code"
				actions={
					activeItem ? (
						<>
							<Button
								variant="secondary"
								onClick={() => setActiveItem(undefined)}
							>
								Cancel
							</Button>
							<Button
								onClick={() => {
									onChangeSelected(activeItem?.id, activeItem);
									setShowDialog(false);
								}}
							>
								Confirm
							</Button>
						</>
					) : (
						<div className={styles.actions}>
							Tap BoP code to select
							<Button variant="secondary" onClick={() => setShowDialog(false)}>
								Back
							</Button>
						</div>
					)
				}
			>
				<div>
					<div className={styles.filters}>
						<div className="relative">
							<Search
								value={search}
								onChange={(event) => onSearchChange(event.target.value, true)}
								placeholder="Search"
								onClear={() => onSearchChange("", true)}
							/>
						</div>
						<SingleSelectDialog
							className={styles.dropdown}
							itemClassName={styles.categoryItem}
							placeholder="Filter by category"
							title="Filter by category"
							value={
								category
									? {
											id: category,
											name: category,
										}
									: undefined
							}
							options={
								categoryOptions?.map((current) => {
									return {
										id: current,
										name: current,
									};
								}) ?? []
							}
							onChange={(option) => {
								const value = option?.id as string;
								onChangeCategory(value === category ? undefined : value);
							}}
						/>
					</div>
					{data?.length === 0 ? (
						<div className={styles.empty}>
							<p className={styles.emptyTitle}>No results found.</p>
							<p>
								If you need help finding a BoP code, contact your Account
								Manager.
							</p>
							<Button
								variant="tertiary"
								centered
								inline
								onClick={() => onSearchChange(undefined, true)}
							>
								Clear search
							</Button>
						</div>
					) : (
						<>
							<h2 className={styles.title}>{data?.length} BoP Codes</h2>
							<ul className={styles.list}>
								{data?.map((item) => (
									<Item
										ref={(ref) => {
											if (ref) {
												const key = `bop-${item.id}-${item.bopCode}`;
												refs.current[key] = ref;
											}
										}}
										selected={activeItem?.id}
										key={item.id}
										data={item}
										onClick={() => setActiveItem(item)}
									/>
								))}
							</ul>
						</>
					)}
				</div>
			</Dialog>
			<Dialog
				isOpen={showConfirmDeselect}
				onClose={() => setShowConfirmDeselect(false)}
				title="Deselect BoP"
				description="Would you like to deselect this BoP?"
				actions={
					<>
						<Button
							variant="secondary"
							onClick={() => setShowConfirmDeselect(false)}
						>
							Cancel
						</Button>
						<Button
							onClick={() => {
								onChangeSelected(undefined, undefined);
								setShowConfirmDeselect(false);
							}}
						>
							Confirm
						</Button>
					</>
				}
			/>
		</>
	);
};
