import { type PayloadAction, createSlice } from "@reduxjs/toolkit";

export type State = {
	setRecipientNicknameLoading?: boolean;
	setRecipientNicknameError?: string[] | null;

	deleteRecipientLoading?: boolean;
	deleteRecipientError?: string[] | null;

	updateRecipientError?: string[] | null;

	recipientId?: number;
	recipientIdLoading?: boolean;
};

export const defaultState: State = {};

const slice = createSlice({
	name: "recipients",
	initialState: defaultState,
	reducers: {
		setSetRecipientNicknameLoading(
			state: State,
			action: PayloadAction<boolean>,
		) {
			state.setRecipientNicknameLoading = action.payload;
		},
		setSetRecipientNicknameError(
			state: State,
			action: PayloadAction<string[] | null>,
		) {
			state.setRecipientNicknameError = action.payload;
		},

		setDeleteRecipientLoading(state: State, action: PayloadAction<boolean>) {
			state.deleteRecipientLoading = action.payload;
		},
		setDeleteRecipientError(
			state: State,
			action: PayloadAction<string[] | null>,
		) {
			state.deleteRecipientError = action.payload;
		},
		setRecipientId(state: State, action: PayloadAction<number | undefined>) {
			state.recipientId = action.payload;
		},
		setRecipientIdLoading(state: State, action: PayloadAction<boolean>) {
			state.recipientIdLoading = action.payload;
		},
	},
});

export const {
	setSetRecipientNicknameLoading,
	setSetRecipientNicknameError,
	setDeleteRecipientLoading,
	setDeleteRecipientError,
	setRecipientId,
	setRecipientIdLoading,
} = slice.actions;

export default slice.reducer;
