import { Calendar } from "primereact/calendar";
import { memo, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";

import type { ViewProperties } from "./properties";

import styles from "./date-picker-view.module.css";

import { joinClasses } from "@app/utils";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import "./date-picker.css";
import { useMediaQuery } from "@app/hooks/use-media-query";
import { isMobileTrayMenuEnabled } from "@app/constants/feature-flags";
import clsx from "clsx";
import { Button } from "@app/components/button";
import { Dialog } from "@app/components/dialog";
import dayjs from "dayjs";

export const DatePickerView = memo((props: ViewProperties) => {
	const isMobile = useMediaQuery();
	const [isOpen, setIsOpen] = useState(false);
	const parentRef = useRef<HTMLDivElement | null>(null);

	const getIconPaddingClass = () => {
		if (props.overrideShowIcon !== false) {
			if (props.iconPosition === "right") return "pr-10";
			return "pl-10";
		}

		return "";
	};

	const getIconPositionClass = () => {
		if (props.iconPosition) {
			if (props.iconPosition === "right") return "date-picker-icon-right";
			return "date-picker-icon-left";
		}
		return "date-picker-no-icon";
	};

	if (isMobile && props.showMobileDialog && isMobileTrayMenuEnabled) {
		return (
			<>
				<button
					data-open={isOpen}
					className={clsx(styles.trigger, props.containerClassName)}
					type="button"
					onClick={() => setIsOpen(true)}
				>
					<svg
						role="presentation"
						width="20"
						height="20"
						viewBox="0 0 20 20"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M17.5 8.33464H2.5M13.3333 1.66797V5.0013M6.66667 1.66797V5.0013M6.5 18.3346H13.5C14.9001 18.3346 15.6002 18.3346 16.135 18.0622C16.6054 17.8225 16.9878 17.44 17.2275 16.9696C17.5 16.4348 17.5 15.7348 17.5 14.3346V7.33464C17.5 5.9345 17.5 5.23444 17.2275 4.69966C16.9878 4.22925 16.6054 3.8468 16.135 3.60712C15.6002 3.33464 14.9001 3.33464 13.5 3.33464H6.5C5.09987 3.33464 4.3998 3.33464 3.86502 3.60712C3.39462 3.8468 3.01217 4.22925 2.77248 4.69966C2.5 5.23444 2.5 5.9345 2.5 7.33464V14.3346C2.5 15.7348 2.5 16.4348 2.77248 16.9696C3.01217 17.44 3.39462 17.8225 3.86502 18.0622C4.3998 18.3346 5.09987 18.3346 6.5 18.3346Z"
							stroke="#888888"
							strokeWidth="1.66667"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>

					<div className={styles.triggerContent}>
						{props.selection?.length ? (
							dayjs(props.selection[0]).format("D MMM YYYY")
						) : (
							<span className={styles.placeholder}>{props.placeholder}</span>
						)}
					</div>
				</button>
				<Dialog
					bottomsheet
					isOpen={isOpen}
					zIndex={103}
					onClose={() => setIsOpen(false)}
					title={props.dialogTitle ?? props.placeholder ?? "Select Date"}
					actions={<Button onClick={() => setIsOpen(false)}>Cancel</Button>}
				>
					<Calendar
						appendTo={props.appendToParent ? parentRef.current : undefined}
						{...props.calendarTypeProps}
						className={twMerge(
							"date-picker",
							"w-100%",
							props.isShown && "date-picker-open",
						)}
						dateFormat="d M yy"
						dateTemplate={props.dateTemplate}
						pt={{
							weekDay: {
								className: "date-picker-weekday",
							},
							weekHeader: {
								className: "week-header",
							},
							day: props.getPassThroughForDay,
							panel: {
								className: props.panelClassName,
							},
						}}
						showIcon={false}
						showOtherMonths
						selectOtherMonths
						enabledDates={props.enabledDates}
						disabled={props.disabled}
						disabledDates={props.disabledDates}
						disabledDays={props.disabledDays}
						inline
						onSelect={() => {
							setIsOpen(false);
						}}
						inputClassName={twMerge(
							getIconPaddingClass(),
							props.inputClassName,
						)}
						locale={props.locale}
						maxDate={props.maxDate}
						maxDateCount={props.maxDateCount}
						minDate={props.minDate}
						numberOfMonths={props.numberOfMonths ?? 1}
						panelClassName="date-picker-panel"
						placeholder={props.placeholder}
						readOnlyInput={props.readOnlyInput}
						required={props.required}
						showButtonBar={props.showButtonBar}
						showWeek={props.showWeek}
						view={props.view ?? "date"}
						viewDate={props.viewDate}
						onShow={props.onShow}
						onHide={props.onHide}
						onViewDateChange={props.onViewSelectionChange}
					/>
				</Dialog>
			</>
		);
	}

	return (
		<div
			ref={(parentDiv) => {
				parentRef.current = parentDiv;
			}}
			className={joinClasses("relative", props.containerClassName)}
		>
			<Calendar
				appendTo={props.appendToParent ? parentRef.current : undefined}
				{...props.calendarTypeProps}
				className={twMerge(
					"date-picker",
					props.isShown && "date-picker-open",
					props.className,
					getIconPositionClass(),
				)}
				dateFormat="d M yy"
				dateTemplate={props.dateTemplate}
				pt={{
					weekDay: {
						className: "date-picker-weekday",
					},
					weekHeader: {
						className: "week-header",
					},
					day: props.getPassThroughForDay,
					panel: {
						className: props.panelClassName,
					},
				}}
				showIcon={props.overrideShowIcon !== false}
				icon={props.icon}
				iconPos={props.iconPosition}
				showOtherMonths
				selectOtherMonths
				enabledDates={props.enabledDates}
				disabled={props.disabled}
				disabledDates={props.disabledDates}
				disabledDays={props.disabledDays}
				inline={props.inline}
				inputClassName={twMerge(getIconPaddingClass(), props.inputClassName)}
				locale={props.locale}
				maxDate={props.maxDate}
				maxDateCount={props.maxDateCount}
				minDate={props.minDate}
				numberOfMonths={props.numberOfMonths ?? 1}
				panelClassName="date-picker-panel"
				placeholder={props.placeholder}
				readOnlyInput={props.readOnlyInput}
				required={props.required}
				showButtonBar={props.showButtonBar}
				showWeek={props.showWeek}
				view={props.view ?? "date"}
				viewDate={props.viewDate}
				onShow={props.onShow}
				onHide={props.onHide}
				onViewDateChange={props.onViewSelectionChange}
			/>
			{props.showChevron && (
				<div className="date-picker-chevron-container">
					{props.isShown ? (
						<FiChevronDown size={20} color="#888" />
					) : (
						<FiChevronUp size={20} color="#888" />
					)}
				</div>
			)}
		</div>
	);
});
