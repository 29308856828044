import { ElementRef, ReactNode, useEffect, useRef, useState } from "react";
import styles from "./index.module.css";

import { Button } from "@app/components/button";
import checkedSrc from "@app/features/onboarding/radio-group-field/checked.svg";
import { Dialog } from "@app/components/dialog";
import { Search } from "@app/components/search";
import chevronUpSrc from "./chevron-up.svg";
import clsx from "clsx";

export interface Option {
	name: string;
	id: number | string;
	subtext?: string;
	icon?: ReactNode;
}

export const SingleSelectDialog = ({
	value,
	options,
	onChange,
	placeholder,
	error,
	itemClassName,
	searchText = "Search",
	title,
	className,
	renderValue,
	renderOption,
	actions,
}: {
	onChange: (value: Option) => void;
	options: Array<Option>;
	value?: Option;
	placeholder?: string;
	searchText?: string;
	error?: boolean;
	title?: string;

	className?: string;
	itemClassName?: string;
	actions?: ReactNode;
	renderValue?: (value: Option) => ReactNode;
	renderOption?: (option: Option) => ReactNode;
}) => {
	const triggerRef = useRef<ElementRef<"button">>(null);
	const [search, setSearch] = useState("");
	const modalListRef = useRef<ElementRef<"div">>(null);
	const [isOpen, setIsOpen] = useState(false);
	const [selected, setSelected] = useState<Option>();
	const [isScrollable, setIsScrollable] = useState(false);

	const filteredOptions = options.filter((option) => {
		return option.name.toLowerCase().includes(search.toLowerCase());
	});

	useEffect(() => {
		if (modalListRef.current && options.length > 0 && isOpen) {
			const { scrollHeight } = modalListRef.current;
			setIsScrollable(scrollHeight > window.innerHeight - 200);
		}
	}, [isOpen, options]);

	useEffect(() => {
		if (isOpen && value) {
			setSelected(value);
		}
	}, [isOpen, value]);

	const handleClose = () => {
		setSearch("");
		setSelected(undefined);
		setIsOpen(false);
	};

	const isBottomsheet = options.length <= 7;

	const isNoResults = filteredOptions.length === 0;

	return (
		<>
			<button
				className={clsx(styles.trigger, className)}
				type="button"
				data-error={error}
				data-open={isOpen}
				ref={triggerRef}
				onClick={() => setIsOpen(true)}
			>
				{value ? (
					renderValue ? (
						renderValue(value)
					) : (
						<span
							className={clsx(styles.modalItemContent, styles.valueContainer)}
						>
							{value.icon}
							<span className={styles.value}>{value.name}</span>
						</span>
					)
				) : (
					<span className={styles.placeholder}>{placeholder}</span>
				)}
				<img
					data-open={isOpen}
					className={styles.chevron}
					src={chevronUpSrc}
					alt=""
					width={20}
					height={20}
				/>
			</button>
			<Dialog
				fullscreen={!isBottomsheet}
				bottomsheet={isBottomsheet}
				isOpen={isOpen}
				className={styles.dialog}
				zIndex={103}
				onClose={handleClose}
				title={title}
				actions={
					<>
						<Button
							variant={actions ? "secondary" : "primary"}
							onClick={handleClose}
						>
							Cancel
						</Button>
						{actions}
					</>
				}
			>
				{isScrollable && (
					<Search
						value={search}
						onChange={(event) => setSearch(event.target.value)}
						placeholder={searchText}
					/>
				)}
				<div
					className={styles.modalList}
					ref={modalListRef}
					data-scrollable={isScrollable}
				>
					{isNoResults ? (
						<div className={styles.noResults}>No results found.</div>
					) : (
						filteredOptions.map((option) => {
							const isChecked = selected?.id === option.id;
							return (
								<button
									type="button"
									className={clsx(styles.modalItem, itemClassName)}
									aria-current={isChecked}
									aria-label={option.name}
									key={option.id}
									onClick={() => {
										setSearch("");
										setSelected(option);
										onChange(option);
										setIsOpen(false);
									}}
								>
									<div className={styles.modalItemContent}>
										{renderOption ? (
											renderOption(option)
										) : (
											<>
												{option.icon}
												<div>
													<p>{option.name}</p>
													{option.subtext && (
														<p className={styles.subtext}>{option.subtext}</p>
													)}
												</div>
											</>
										)}
									</div>
									{isChecked ? (
										<img src={checkedSrc} alt="" width={16} height={16} />
									) : (
										<div className={styles.unchecked} />
									)}
								</button>
							);
						})
					)}
				</div>
			</Dialog>
		</>
	);
};
