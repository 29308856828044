import useSWR from "swr";
import { useClients } from "./use-clients";

export type SettlementAccount = {
	bank_account_id: number;
	account_type: string;
	account_number: string;
	primary_account: boolean;
};

export const useSettlementAccountOptions = () => {
	const { activeClientId } = useClients();
	const { data, error, isLoading } = useSWR<{
		settlement_accounts: Array<SettlementAccount>;
	}>(activeClientId ? `clients/${activeClientId}/forex-quote/options/` : null);

	return {
		data,
		error,
		isLoading,
	};
};
