import {
	type Apn,
	type CreateApnParams,
	type CreateSupportingDocument,
	type CreateSupportingDocumentParams,
	type GetBopDetails,
	type GetPaymentBopDocs,
	type ListPaymentBop,
	type ListSuggestedPaymentBop,
	type Payment,
	type PaymentBopOptions,
	type PaymentFeeTypeOption,
	type PaymentFeeTypes,
	type SuggestedPaymentBop,
	type SupportingDocument,
	type UpdatePayment,
	type UpdatePaymentBop,
	type UpdatePaymentBopParams,
	type UpdatePaymentFieldsParams,
	type UpdatePaymentParams,
} from "@app/entities";
import {
	concatenateArrays,
	convertBlobToBase64,
	getMappedReasons,
} from "@app/utils";

import { dateFormats } from "@app/constants/date-formats";
import { toDayjs } from "@app/lib/date";
import { getFilenameFromHeader } from "@app/utils/get-filename-from-header";
import { getReasons } from "@app/utils/get-reasons";
import { api } from "./api";
import type {
	ApnRequest,
	CreateSupportingDocumentResponse,
	GenericFailureResponse,
	GenericResponse,
	GetListPaymentBopResponse,
	GetPaymentBopDocsResponse,
	GetPaymentBopResponse,
	ListSuggestedPaymentBopResponse,
	PaymentBopOptionResponse,
	PaymentBopQueryParams,
	PaymentFeeTypeOptionResponse,
	PaymentFeeTypesResponse,
	PaymentResponse,
	SuggestedPaymentBopResponse,
	SupportingDocResponse,
	UpdatePaymentBopRequest,
	UpdatePaymentFieldsRequest,
	UpdatePaymentRequest,
} from "./models";

export async function getPaymentFeeTypeOptions(
	paymentId: number,
): Promise<PaymentFeeTypes | GenericResponse> {
	try {
		const { data } = await api.get(`payments/${paymentId}/options/`);
		return mapPaymentFeeType(data);
	} catch (exception: any) {
		const reasons = getReasons(exception);
		return {
			reasons,
		};
	}
}

export async function getPayment(paymentId: number) {
	try {
		const { data } = await api.get(`payments/${paymentId}`);
		return mapToPayment(data);
	} catch (exception: any) {
		const error = exception.data as GenericFailureResponse;
		const reasons = getReasons(exception);
		const mappedReasons = getMappedReasons(error);

		return {
			reasons,
			mappedReasons,
		};
	}
}

export async function createApn(params: CreateApnParams) {
	try {
		const { status } = await api.post(
			`payments/${params.paymentId}/apn-details/`,
			mapToApnRequest(params.apn),
		);
		return status;
	} catch (exception: any) {
		const error = exception.data as GenericFailureResponse;
		const mappedReasons = getMappedReasons(error);

		return {
			reasons: [],
			mappedReasons,
		};
	}
}

export async function createSupportingDocument(
	params: CreateSupportingDocumentParams,
) {
	try {
		const { data } = await api.post(
			`payments/${params.paymentId}/supporting-documents/`,
			mapToCreateSupportingDocumentFormRequest(params.supportingDocument),
			{
				onUploadProgress: params.onUploadProgress,
			},
		);
		return mapToCreateSupportingDocumentResponse(data);
	} catch (exception: any) {
		const error = exception.data as GenericFailureResponse;
		const reasons = getReasons(exception);
		const mappedReasons = getMappedReasons(error);

		return {
			reasons,
			mappedReasons,
		};
	}
}

export async function deleteSupportingDocument(documentId: number) {
	try {
		const { status } = await api.delete(`supporting-documents/${documentId}/`);
		return status;
	} catch (exception: any) {
		const error = exception.data as GenericFailureResponse;
		const reasons = getReasons(exception);
		const mappedReasons = getMappedReasons(error);
		return {
			reasons,
			mappedReasons,
		};
	}
}

export async function getPaymentBop(
	payment_id: number,
): Promise<GetBopDetails | GenericResponse> {
	try {
		const { data } = await api.get<GetPaymentBopResponse>(
			`payments/${payment_id}/bop/`,
		);
		return mapToGetPaymentBop(data);
	} catch (exception: any) {
		const error = exception.data as GenericFailureResponse;
		const reasons = concatenateArrays([(error.detail as string) ?? ""]);
		return {
			reasons,
		};
	}
}
export async function updatePayment(paymentParams: UpdatePaymentParams) {
	try {
		const { status } = await api.put(
			`payments/${paymentParams.paymentId}/`,
			mapToUpdatePayment(paymentParams.payment),
		);
		return status;
	} catch (exception: any) {
		const error = exception.data as GenericFailureResponse;
		const mappedReasons = getMappedReasons(error);
		return {
			reasons: [],
			mappedReasons,
		};
	}
}

export async function getPaymentBops(
	queryParams: PaymentBopQueryParams,
): Promise<ListPaymentBop | GenericResponse> {
	try {
		// Intentionally set to very high max to ensure getting all records
		const listOfVariables = [`limit=${1000}`, `offset=${0}`];

		if (queryParams.payment_bop.search_query)
			listOfVariables.push(
				`search_query=${queryParams.payment_bop.search_query}`,
			);

		if (queryParams.payment_bop.bop_category_group)
			listOfVariables.push(
				`bop_category_group=${queryParams.payment_bop.bop_category_group}`,
			);

		if (
			queryParams.payment_bop.ordering &&
			queryParams.payment_bop.ordering !== "null"
		)
			listOfVariables.push(`ordering=${queryParams.payment_bop.ordering}`);

		const { data } = await api.get(
			`payments/${queryParams.payment_id}/bops/?${listOfVariables.join("&")}`,
		);
		return mapToListPaymentBop(data);
	} catch (exception: any) {
		const reasons = getReasons(exception);

		return {
			reasons,
		};
	}
}

export async function getPaymentBopDocs(
	payment_id: number,
): Promise<GetPaymentBopDocs[] | GenericResponse> {
	try {
		const { data } = await api.get<GetPaymentBopDocsResponse[]>(
			`payments/${payment_id}/bop-documents/`,
		);
		return data.map((x) => mapToGetPaymentBopDocs(x));
	} catch (exception: any) {
		const error = exception.data as GenericFailureResponse;
		const reasons = concatenateArrays([(error.detail as string) ?? ""]);
		return {
			reasons,
		};
	}
}

export async function ListSuggestedPaymentBops(
	paymentId: number,
): Promise<ListSuggestedPaymentBop | GenericResponse> {
	try {
		const { data } = await api.get<ListSuggestedPaymentBopResponse>(
			`payments/${paymentId}/suggested-bops/`,
		);
		return mapListSuggestedPaymentBops(data);
	} catch (exception: any) {
		const error = exception.data as GenericFailureResponse;
		const reasons = concatenateArrays([(error.detail as string) ?? ""]);

		return {
			reasons,
		};
	}
}

export async function GetPaymentBopOptions(
	paymentId: number,
): Promise<PaymentBopOptions | GenericResponse> {
	try {
		const { data } = await api.get<PaymentBopOptionResponse>(
			`payments/${paymentId}/bops/options/`,
		);
		return mapPaymentBopOptions(data);
	} catch (exception: any) {
		const error = exception.data as GenericFailureResponse;
		const reasons = concatenateArrays([(error.detail as string) ?? ""]);

		return {
			reasons,
		};
	}
}

export async function downloadPaymentSupportingDocs(
	document_id: number,
): Promise<SupportingDocResponse | GenericResponse> {
	try {
		const { data, headers } = await api.get(
			`supporting-documents/${document_id}/`,
			{
				responseType: "blob",
			},
		);

		const fileName = getFilenameFromHeader(
			headers,
			`Supporting Document ${document_id}.pdf`,
		);
		const contentType = headers["content-type"];

		let fileExtension = ".pdf";

		switch (contentType) {
			case "image/jpeg": {
				fileExtension = ".jpg";
				break;
			}
			case "image/png": {
				fileExtension = ".png";
				break;
			}
			default:
				break;
		}

		return {
			fileName: fileName,
			contentType: contentType,
			data: await convertBlobToBase64(data),
		};
	} catch (exception: any) {
		const error = exception.data as GenericFailureResponse;
		const reasons = concatenateArrays([(error.detail as string) ?? ""]);
		return {
			reasons,
		};
	}
}

export async function updateBop(bopParams: UpdatePaymentBopParams) {
	try {
		const { status } = await api.put(
			`payments/${bopParams.paymentId}/bop/`,
			mapToUpdatePaymentBop(bopParams.bop),
		);
		return status;
	} catch (exception: any) {
		const error = exception.data as GenericFailureResponse;
		const reasons = getReasons(exception);
		const mappedReasons = getMappedReasons(error);

		return {
			reasons,
			mappedReasons,
		};
	}
}

export async function submitPayment(paymentId: number) {
	try {
		const { status } = await api.post(`payments/${paymentId}/submit/`);
		return status;
	} catch (exception: any) {
		const error = exception.data as GenericFailureResponse;
		const reasons = getReasons(exception);
		const mappedReasons = getMappedReasons(error);

		return {
			reasons,
			mappedReasons,
		};
	}
}

export async function updatePaymentFields(params: UpdatePaymentFieldsParams) {
	try {
		const { status } = await api.patch(
			`payments/${params.paymentId}/`,
			mapToUpdatePaymentFields(params),
		);

		return status;
	} catch (exception: any) {
		const error = exception.data as GenericFailureResponse;
		const reasons = getReasons(exception);
		const mappedReasons = getMappedReasons(error);

		return {
			reasons,
			mappedReasons,
		};
	}
}

function mapToPayment(value: PaymentResponse): Payment {
	return {
		amountDue: value.amount_due,
		feeType: value.fee_type,
		paymentReference: value.payment_reference,
		proofOfPaymentReady: value.proof_of_payment_ready,
		purposeOfPayment: value.purpose_of_payment,
		recipientId: value.recipient_id,

		swiftAmount: value.swift_amount,
		swiftFee: value.swift_fee,
		swiftFeeTooltip: value.swift_fee_tooltip,
	};
}

function mapToListPaymentBop(value: GetListPaymentBopResponse): ListPaymentBop {
	return {
		count: value.count,
		items: value.items.map((x) => mapToGetPaymentBop(x)),
	};
}

function mapToGetPaymentBop(value: GetPaymentBopResponse): GetBopDetails {
	return {
		bopCode: value.bop_code,
		category: value.category,
		description: value.description,
		id: value.id,
	};
}

function mapSuggestedPaymentBop(
	value: SuggestedPaymentBopResponse,
): SuggestedPaymentBop {
	return {
		bopCode: value.bop_code,
		category: value.category,
		id: value.id,
		description: value.description,
	};
}

function mapListSuggestedPaymentBops(
	value: ListSuggestedPaymentBopResponse,
): ListSuggestedPaymentBop {
	return {
		popular: value.popular.map((x) => mapSuggestedPaymentBop(x)),
		recentlyUsed: value.recently_used.map((x) => mapSuggestedPaymentBop(x)),
	};
}

function mapPaymentBopOptions(
	value: PaymentBopOptionResponse,
): PaymentBopOptions {
	return {
		allowedHeaders: value.allowed_headers,
		allowedMethods: value.allowed_methods,
		listBopCategoryGroups: value.list_bop_category_groups,
		ordering: value.ordering,
	};
}

function mapToGetPaymentBopDocs(
	value: GetPaymentBopDocsResponse,
): GetPaymentBopDocs {
	return {
		documentName: value.document_name,
		documentTypeId: value.document_type_id,
		required: value.required,
	};
}

function mapToApnRequest(value: Apn): ApnRequest {
	return {
		issue_date: value.issueDate
			? toDayjs(value.issueDate).format(dateFormats.iso8601)
			: undefined,
		reference_number: value.referenceNumber,
	};
}

function mapToCreateSupportingDocumentFormRequest(
	value: SupportingDocument,
): FormData {
	const formData = new FormData();

	formData.append("document_type_id", value.documentTypeId.toString());
	formData.append("file", value.file);

	return formData;
}

function mapToCreateSupportingDocumentResponse(
	value: CreateSupportingDocumentResponse,
): CreateSupportingDocument {
	return {
		documentId: value.document_id,
	};
}

function mapPaymentFeeType(value: PaymentFeeTypesResponse): PaymentFeeTypes {
	return {
		allowedFeeTypes: value.allowed_fee_types?.map((x) =>
			mapPaymentFeeTypeOption(x),
		),
		displayApnDetails: value.display_apn_details,
		note: value.note,
	};
}

function mapPaymentFeeTypeOption(
	value: PaymentFeeTypeOptionResponse,
): PaymentFeeTypeOption {
	return {
		amount: value.amount,
		description: value.description,
		feeType: value.fee_type,
		label: value.label,
	};
}

function mapToUpdatePaymentBop(
	value: UpdatePaymentBop,
): UpdatePaymentBopRequest {
	return {
		bop_id: value.bopId,
	};
}

function mapToUpdatePayment(value: UpdatePayment): UpdatePaymentRequest {
	return {
		fee_type: value.feeType,
		payment_reference: value.paymentReference,
		purpose_of_payment: value.purposeOfPayment,
		recipient: value.recipient,
	};
}

function mapToUpdatePaymentFields(
	value: UpdatePaymentFieldsParams,
): UpdatePaymentFieldsRequest {
	const body: UpdatePaymentFieldsRequest = {};
	if (value.paymentReference) body.payment_reference = value.paymentReference;
	if (value.recipient) body.recipient = value.recipient;
	if (value.feeType) body.fee_type = value.feeType;
	if (value.purposeOfPayment) body.purpose_of_payment = value.purposeOfPayment;
	return body;
}
