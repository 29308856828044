import { useDispatch } from "react-redux";

import type {
	CreateApnParams,
	CreateSupportingDocument,
	CreateSupportingDocumentParams,
	GetBopDetails,
	ListPaymentBop,
	ListSuggestedPaymentBop,
	PaymentBopOptions,
	UpdatePaymentBopParams,
	UpdatePaymentFieldsParams,
	UpdatePaymentParams,
} from "@app/entities";
import type { AppDispatch } from "@app/redux";
import {
	clearAllPaymentInfoAction,
	createApnAction,
	createSupportingDocumentAction,
	deleteSupportingDocumentAction,
	downloadPaymentSupportingDocsAction,
	getListPaymentBopsAction,
	getListSuggestedPaymentBopsAction,
	getPaymentBopAction,
	getPaymentBopsOptionsAction,
	updateBopAction,
	updatePaymentAction,
	updatePaymentFieldsAction,
} from "@app/redux/payments/payments-actions";
import type {
	MappedReasons,
	PaymentBopQueryParams,
	SupportingDocResponse,
} from "@app/services";

export const useGetPaymentBop = () => {
	const dispatch: AppDispatch = useDispatch();

	const getPaymentBop = (
		payment_id: number,
		callback?: (response: GetBopDetails | string[] | undefined) => void,
	) => {
		dispatch(getPaymentBopAction(payment_id, callback));
	};

	return [getPaymentBop];
};

export const useDownloadPaymentSupportingDocs = () => {
	const dispatch: AppDispatch = useDispatch();

	const downloadSupportingDoc = (
		document_id: number,
		callback?: (response: SupportingDocResponse | string[] | undefined) => void,
	) => {
		dispatch(downloadPaymentSupportingDocsAction(document_id, callback));
	};

	return [downloadSupportingDoc];
};

export const useClearAllPaymentInfo = () => {
	const dispatch: AppDispatch = useDispatch();

	const clearAllPaymentInfo = () => {
		dispatch(clearAllPaymentInfoAction());
	};

	return [clearAllPaymentInfo];
};

export const useCreateApn = () => {
	const dispatch: AppDispatch = useDispatch();

	const createApn = (
		params: CreateApnParams,
		callback?: (errors?: string[], mappedReasons?: MappedReasons) => void,
	) => {
		dispatch(createApnAction(params, callback));
	};

	return [createApn];
};

export const useCreateSupportingDocument = () => {
	const dispatch: AppDispatch = useDispatch();

	const createSupportingDocument = (
		params: CreateSupportingDocumentParams,
		callback?: (
			response?: CreateSupportingDocument,
			errors?: string[],
			mappedReasons?: MappedReasons,
		) => void,
	) => {
		dispatch(createSupportingDocumentAction(params, callback));
	};

	return [createSupportingDocument];
};

export const useDeleteSupportingDocument = () => {
	const dispatch: AppDispatch = useDispatch();

	const deleteSupportingDocument = (
		documentId: number,
		callback?: (errors?: string[], mappedReasons?: MappedReasons) => void,
	) => {
		dispatch(deleteSupportingDocumentAction(documentId, callback));
	};

	return [deleteSupportingDocument];
};

export const useListPaymentBops = () => {
	const dispatch: AppDispatch = useDispatch();

	const listPaymentBops = (
		queryParams: PaymentBopQueryParams,
		callback?: (response: ListPaymentBop | string[] | undefined) => void,
	) => {
		dispatch(getListPaymentBopsAction(queryParams, callback));
	};

	return [listPaymentBops];
};

export const useListSuggestedPaymentBops = () => {
	const dispatch: AppDispatch = useDispatch();

	const listSuggestedPaymentBops = (
		paymentId: number,
		callback?: (
			response: ListSuggestedPaymentBop | string[] | undefined,
			mappedReasons?: MappedReasons,
		) => void,
	) => {
		dispatch(getListSuggestedPaymentBopsAction(paymentId, callback));
	};

	return [listSuggestedPaymentBops];
};

export const useGetPaymentBopOptions = () => {
	const dispatch: AppDispatch = useDispatch();

	const getPaymentBopOptions = (
		paymentId: number,
		callback?: (response: PaymentBopOptions | string[] | undefined) => void,
	) => {
		dispatch(getPaymentBopsOptionsAction(paymentId, callback));
	};

	return [getPaymentBopOptions];
};

export const useUpdateBop = () => {
	const dispatch: AppDispatch = useDispatch();

	const updateBop = (
		bopParams: UpdatePaymentBopParams,
		callback?: (errors?: string[], mappedReasons?: MappedReasons) => void,
	) => {
		dispatch(updateBopAction(bopParams, callback));
	};

	return [updateBop];
};

export const useUpdatePayment = () => {
	const dispatch: AppDispatch = useDispatch();

	const updatePayment = (
		paymentParams: UpdatePaymentParams,
		callback?: (errors?: string[], mappedReasons?: MappedReasons) => void,
	) => {
		dispatch(updatePaymentAction(paymentParams, callback));
	};

	return [updatePayment];
};

export const useUpdatePaymentFields = () => {
	const dispatch: AppDispatch = useDispatch();

	const updatePaymentFields = (
		paymentParams: UpdatePaymentFieldsParams,
		callback?: (errors?: string[], mappedReasons?: MappedReasons) => void,
	) => {
		dispatch(updatePaymentFieldsAction(paymentParams, callback));
	};

	return [updatePaymentFields];
};
