import { Button } from "@app/components/button";
import { Search } from "@app/components/search";

import styles from "./index.module.css";
import { useMemo, useState } from "react";
import { Dialog } from "@app/components/dialog";
import { TransactionsFilterState } from "../models/models";
import { TransactionDirection } from "@app/entities";
import { RecipientNames } from "@app/hooks/use-recipient-names";
import { MappedCurrency } from "@app/hooks/use-currencies";
import { MultiSelectNew } from "@app/features/onboarding/business-company-details/multi-select-new";
import { Label } from "@app/components/label";
import { TagGroup } from "./tag-group";
import { DatePicker } from "@app/components/controls";
import dayjs from "dayjs";
import { capitaliseFirstLetter } from "@app/utils";

import receiveSrc from "./receive.svg";
import sendSrc from "./send.svg";
import { toDayjs } from "@app/lib/date";

const getFormattedDate = (date?: Date): string => {
	if (!date) {
		return "";
	}
	const dDate = toDayjs(date);
	return `${dDate.format("DD MMM YYYY")}`;
};

export const MobileTransactionFilters = ({
	searchValue,
	onSearchChange,
	filterState,
	onChangeRecipientFilters,
	onChangeCurrencyFilters,
	onChangeStartDateFilter,
	onChangeEndDateFilter,
	onChangeDirectionFilter,
	onApplyFilters,
	onClearFilters,
	onRemoveCurrencyFilterTag,
	onRemoveCurrencyFiltersTag,
	onRemoveDirectionFilterTag,
	onRemoveDirectionFiltersTag,
	onRemoveRecipientFilterTag,
	onRemoveRecipientFiltersTag,
	onRemoveDateFiltersTag,
}: {
	searchValue: string;
	onSearchChange: (value: string) => void;
	filterState: TransactionsFilterState;
	onChangeCurrencyFilters: (value: MappedCurrency[]) => void;
	onChangeDirectionFilter: (value?: TransactionDirection[]) => void;
	onChangeEndDateFilter: (value: Date) => void;
	onChangeRecipientFilters: (value: RecipientNames[]) => void;
	onChangeStartDateFilter: (value: Date) => void;
	onApplyFilters: () => void;
	onClearFilters: () => void;
	onRemoveCurrencyFilterTag: (value: MappedCurrency) => void;
	onRemoveCurrencyFiltersTag: () => void;
	onRemoveDirectionFilterTag: (value: TransactionDirection) => void;
	onRemoveDirectionFiltersTag: (value?: TransactionDirection[]) => void;
	onRemoveRecipientFilterTag: (value: RecipientNames) => void;
	onRemoveRecipientFiltersTag: () => void;
	onRemoveDateFiltersTag: () => void;
}) => {
	const [showDialog, setShowDialog] = useState(false);

	const appliedFiltersCount = useMemo(() => {
		let count = 0;
		if (filterState.appliedCurrenciesSelected?.length) {
			count += filterState.appliedCurrenciesSelected.length;
		}
		if (filterState.appliedDirectionsSelected?.length) {
			count += filterState.appliedDirectionsSelected.length;
		}
		if (filterState.appliedRecipientNamesSelected?.length) {
			count += filterState.appliedRecipientNamesSelected.length;
		}
		if (filterState.appliedStartDate) {
			count++;
		}
		if (filterState.appliedEndDate) {
			count++;
		}
		return count;
	}, [filterState]);

	const hasDateFilter =
		filterState.appliedStartDate || filterState.appliedEndDate;

	return (
		<>
			<div className={styles.container}>
				<div className={styles.filters}>
					<Search
						placeholder="Search"
						value={searchValue}
						onChange={(event) => onSearchChange(event.target.value)}
					/>
					<Button
						className={styles.filterButton}
						variant="secondary"
						onClick={() => setShowDialog(true)}
						style={{ maxWidth: appliedFiltersCount > 0 ? 110 : 86 }}
					>
						Filter {appliedFiltersCount > 0 ? `(${appliedFiltersCount})` : ""}
					</Button>
				</div>
				{appliedFiltersCount > 0 && (
					<div className={styles.tags}>
						{filterState.appliedCurrenciesSelected && (
							<TagGroup
								name="Currencies"
								data={filterState.appliedCurrenciesSelected.map((currency) => ({
									id: currency.currencyCode,
									name: currency.currencyCode,
								}))}
								variant="currencies"
								onRemove={(id) => {
									if (!filterState.appliedCurrenciesSelected) {
										return;
									}
									const current = filterState.appliedCurrenciesSelected.find(
										(currency) => currency.currencyCode === id,
									);
									if (current) {
										onRemoveCurrencyFilterTag(current);
									}
								}}
								onRemoveAll={onRemoveCurrencyFiltersTag}
							/>
						)}
						{filterState.appliedRecipientNamesSelected && (
							<TagGroup
								name="Recipients"
								data={filterState.appliedRecipientNamesSelected}
								onRemove={(id) => {
									if (!filterState.appliedRecipientNamesSelected) {
										return;
									}
									const current =
										filterState.appliedRecipientNamesSelected.find(
											(recipient) => recipient.id === id,
										);
									if (current) {
										onRemoveRecipientFilterTag(current);
									}
								}}
								onRemoveAll={onRemoveRecipientFiltersTag}
							/>
						)}
						{filterState.appliedDirectionsSelected && (
							<TagGroup
								name="Transaction Direction"
								data={filterState.appliedDirectionsSelected.map(
									(direction) => ({
										id: direction,
										name: capitaliseFirstLetter(direction),
									}),
								)}
								variant="transction-direction"
								onRemove={(id) => {
									if (!filterState.appliedDirectionsSelected) {
										return;
									}
									const current = filterState.appliedDirectionsSelected.find(
										(direction) => direction === id,
									);
									if (current) {
										onRemoveDirectionFilterTag(current);
									}
								}}
								onRemoveAll={onRemoveDirectionFiltersTag}
							/>
						)}
						{hasDateFilter && (
							<TagGroup
								name="Date range"
								data={[
									{
										id: "date",
										name:
											filterState.appliedStartDate && filterState.appliedEndDate
												? `${getFormattedDate(
														filterState.appliedStartDate ?? undefined,
													)}  - ${getFormattedDate(
														filterState.appliedEndDate ?? undefined,
													)}`
												: filterState.appliedStartDate
													? `From ${getFormattedDate(
															filterState.appliedStartDate,
														)}`
													: `Up to ${getFormattedDate(
															filterState.appliedEndDate,
														)}`,
									},
								]}
								onRemove={() => {
									onRemoveDateFiltersTag();
								}}
								onRemoveAll={onRemoveDateFiltersTag}
							/>
						)}
					</div>
				)}
			</div>
			<Dialog
				showTopbar
				fullscreen
				isOpen={showDialog}
				onClose={() => setShowDialog(false)}
				title="Filter"
				actions={
					<>
						<Button
							variant="secondary"
							onClick={() => {
								setShowDialog(false);
								onClearFilters();
							}}
						>
							Clear all
						</Button>
						<Button
							onClick={() => {
								setShowDialog(false);
								onApplyFilters();
							}}
						>
							Apply
						</Button>
					</>
				}
			>
				<form className={styles.form}>
					<div>
						<Label htmlFor="recipient">Recipient</Label>
						<MultiSelectNew
							mobileHeading="Select recipients"
							placeholder="Select recipients"
							options={
								filterState.recipientNameOptions?.map((recipient) => ({
									id: recipient.id,
									name: recipient.name,
								})) ?? []
							}
							onChange={(value) => {
								const current = filterState.recipientNameOptions?.filter(
									(recipient) => value.some((v) => v.id === recipient.id),
								);
								if (current) {
									onChangeRecipientFilters(current);
								}
							}}
							value={
								filterState.currentRecipientNamesSelected?.map((recipient) => ({
									id: recipient.id,
									name: recipient.name,
								})) ?? []
							}
						/>
					</div>

					<div>
						<Label htmlFor="type">Type</Label>
						<ul className={styles.directionContainer}>
							{filterState.directionOptions?.map((direction) => (
								<li className={styles.directionItem} key={direction}>
									<button
										data-active={filterState.currentDirectionsSelected?.includes(
											direction,
										)}
										className={styles.directionButton}
										type="button"
										onClick={() => onChangeDirectionFilter([direction])}
									>
										{direction === "send" && (
											<img src={sendSrc} alt="" width={24} height={24} />
										)}

										{direction === "receive" && (
											<img src={receiveSrc} alt="" width={24} height={24} />
										)}

										{capitaliseFirstLetter(direction)}
									</button>
								</li>
							))}
						</ul>
					</div>

					<div>
						<Label htmlFor="currency">Currency</Label>
						<MultiSelectNew
							mobileHeading="Select currencies"
							placeholder="Select currencies"
							options={
								filterState.currencyOptions?.map((current) => ({
									id: current.currencyCode,
									countryCode: current.countryCode,
									name: current.verboseName,
								})) ?? []
							}
							onChange={(value) => {
								const values = value.map((value) => ({
									currencyCode: value.id.toString(),
									countryCode: value.countryCode!,
									verboseName: value.name,
								}));
								onChangeCurrencyFilters(values);
							}}
							value={
								filterState.currentCurrenciesSelected?.map((currency) => ({
									id: currency.currencyCode,
									countryCode: currency.countryCode,
									name: currency.verboseName,
								})) ?? []
							}
						/>
					</div>

					<div className={styles.dateContainer}>
						<div className={styles.datePickerContainer}>
							<Label htmlFor="start-date">Start Date</Label>
							<DatePicker
								showMobileDialog
								placeholder="Select a date"
								maxDate={filterState.currentEndDate ?? dayjs().toDate()}
								value={
									filterState.currentStartDate
										? [filterState.currentStartDate]
										: []
								}
								onChange={(value) => onChangeStartDateFilter(value[0])}
							/>
						</div>
						<div className={styles.dateSeparator}>-</div>
						<div className={styles.datePickerContainer}>
							<Label htmlFor="end-date">End Date</Label>
							<DatePicker
								showMobileDialog
								placeholder="Select a date"
								minDate={filterState.currentStartDate}
								maxDate={dayjs().toDate()}
								value={
									filterState.currentEndDate ? [filterState.currentEndDate] : []
								}
								onChange={(value) => onChangeEndDateFilter(value[0])}
							/>
						</div>
					</div>
				</form>
			</Dialog>
		</>
	);
};
