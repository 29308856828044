import { api } from "@app/services";
import { convertBlobToBase64 } from "@app/utils";
import useSWR from "swr";
import { useClients } from "./use-clients";
import { AxiosProgressEvent } from "axios";
import { getFormErrors } from "@app/utils/get-form-errors";

export const useProfilePicture = () => {
	const { activeClientId } = useClients();
	const result = useSWR<
		| {
				data: Blob;
				base64: string;
				contentType: string;
		  }
		| undefined
	>(
		activeClientId ? `/clients/${activeClientId}/profile-picture/` : null,
		async (path: string) => {
			const { data, headers } = await api.get(path, {
				responseType: "blob",
			});
			return {
				data,
				base64: await convertBlobToBase64(data),
				contentType: headers["content-type"],
			};
		},
	);

	return {
		...result,
		updateProfilePicture: async (
			profilePicture: File | undefined,
			onUploadProgress?: (progressEvent: AxiosProgressEvent) => void,
		) => {
			try {
				const formData = new FormData();
				formData.append("image", profilePicture!);
				await api.post(`clients/${activeClientId}/profile-picture/`, formData, {
					onUploadProgress,
				});
				await result.mutate();
			} catch (error) {
				return getFormErrors(error);
			}
		},
	};
};
