import removeSrc from "./remove.svg";

import styles from "./index.module.css";
import { CountryIcon } from "@app/components/country-icon";

import sendIcon from "../send.svg";
import receiveIcon from "../receive.svg";

export const TagGroup = ({
	name,
	maxSize = 3,
	data,
	variant = "default",
	onRemove,
	onRemoveAll,
}: {
	name: string;
	maxSize?: number;
	data: Array<{
		id: number | string;
		name: string;
	}>;
	onRemove: (id: number | string) => void;
	onRemoveAll: () => void;
	variant?: "default" | "currencies" | "transction-direction";
}) => {
	if (data.length === 0) return null;
	if (data.length > maxSize) {
		return (
			<div className={styles.tag}>
				{name}
				<span className={styles.count}>{data.length}</span>
				<button
					className={styles.removeButton}
					type="button"
					onClick={onRemoveAll}
				>
					<img src={removeSrc} alt="Remove" width={14} height={14} />
				</button>
			</div>
		);
	}
	return data.map((item) => (
		<div key={item.id} className={styles.tag}>
			{variant === "currencies" && (
				<CountryIcon currencyCode={item.id as string} width={20} height={20} />
			)}
			{variant === "transction-direction" && item.id === "send" && (
				<img src={sendIcon} width={24} height={24} alt="" />
			)}
			{variant === "transction-direction" && item.id === "receive" && (
				<img src={receiveIcon} width={24} height={24} alt="" />
			)}
			{item.name}
			<button
				className={styles.removeButton}
				type="button"
				onClick={() => onRemove(item.id)}
			>
				<img src={removeSrc} alt="Remove" width={14} height={14} />
			</button>
		</div>
	));
};
