import { captureWithContext } from "./capture-with-context";
import {
	genericErrorAtom,
	genericErrorStore,
} from "@app/components/generic-error-modal";
import { captureException } from "@sentry/react";

export const handleGeneralError = (data?: any) => {
	if (data) {
		if (data instanceof Error) {
			captureException(data);
		} else {
			captureWithContext(
				new Error(data?.message ?? "Redirect to error page"),
				data,
				"error",
			);
		}
	}
	genericErrorStore.set(genericErrorAtom, true);
};
