import { useState } from "react";

import { useMediaQuery } from "@app/hooks/use-media-query";
import { InputSwitch } from "@app/components/input-switch";
import { RadioGroup } from "@app/components/radio-group";
import styles from "../index.module.css";
import { OTPMethod, useOTP } from "@app/features/otp/use-otp";
import { EnableOTPDeviceFlow } from "@app/features/otp/enable-otp-device-flow";
import { DisableOTPDeviceFlow } from "@app/features/otp/disable-otp-device-flow";
import { ChangeOTPNumberFlow } from "@app/features/otp/change-otp-number-flow";
import { IconButton } from "@app/components/icon-button";
import { Button } from "@app/components/button";
import penIconSrc from "@app/assets/images/pen-icon.svg";
import { formatPhoneNumber } from "@app/utils/format-phone-number";
import { useShowToast } from "@app/helpers";

const DELIVERY_METHOD_OPTIONS = [
	{ label: "SMS", value: "sms" },
	{ label: "Whatsapp", value: "whatsapp" },
];

export const OtpAuthentication = () => {
	const [showToast] = useShowToast();
	const [isUpdatingChannel, setIsUpdatingChannel] = useState(false);
	const [showEnableOTPFlow, setShowEnableOTPFlow] = useState(false);
	const [showDisableOTPFlow, setShowDisableOTPFlow] = useState(false);
	const [showChangeOTPNumberFlow, setShowChangeOTPNumberFlow] = useState(false);
	const isMobile = useMediaQuery();
	const { otpDetails, mutate, changeChannel } = useOTP();

	const isOTPEnabled = otpDetails?.status === "enabled";

	const handleToggleOTP = () => {
		if (!isOTPEnabled) {
			setShowEnableOTPFlow(true);
		} else {
			setShowDisableOTPFlow(true);
		}
	};

	const showOTPNumber = isOTPEnabled && !!otpDetails?.contact_number;
	const showOTPChannel = isOTPEnabled && otpDetails?.message_channel;

	return (
		<>
			<div className={styles.subtitleContainer}>
				<h3 className={styles.subtitle}>OTP Authentication</h3>
				<div className={styles.inputSwitch}>
					<InputSwitch
						id="otpAuthentication"
						checked={isOTPEnabled}
						onChange={handleToggleOTP}
					/>
					<label
						className={styles.inputSwitchLabel}
						htmlFor="otpAuthentication"
					>
						{otpDetails?.status === "enabled" ? "Enabled" : "Disabled"}
					</label>
				</div>
			</div>

			{showOTPNumber && (
				<div className={styles.settingsRow}>
					<div>
						<p className={styles.settingsLabel}>
							Mobile Number (used for OTPs)
						</p>
						<p className={styles.settingsValue}>
							{formatPhoneNumber(otpDetails.contact_number)}
						</p>
					</div>
					{isMobile ? (
						<IconButton onClick={() => setShowChangeOTPNumberFlow(true)}>
							<img src={penIconSrc} alt="edit-icon" />
						</IconButton>
					) : (
						<Button
							className={styles.settingsButton}
							onClick={() => setShowChangeOTPNumberFlow(true)}
							variant="secondary"
						>
							Change OTP number
						</Button>
					)}
				</div>
			)}

			{showOTPChannel && (
				<div className={styles.settingsRow}>
					<div>
						<p className={styles.settingsLabel}>Default OTP Delivery Method</p>

						<RadioGroup
							name="delivery-method"
							disabled={isUpdatingChannel}
							value={otpDetails.message_channel}
							options={DELIVERY_METHOD_OPTIONS}
							onChange={async (method: OTPMethod) => {
								setIsUpdatingChannel(true);
								const errors = await changeChannel(method);
								setIsUpdatingChannel(false);
								if (errors && errors?.apiErrors.length > 0) {
									showToast(errors.apiErrors[0], "error");
									return;
								}
								showToast("OTP delivery method changed", "success");
							}}
						/>
					</div>
				</div>
			)}

			{showEnableOTPFlow && (
				<EnableOTPDeviceFlow
					onComplete={() => {
						mutate();
						setShowEnableOTPFlow(false);
					}}
					onClose={() => {
						setShowEnableOTPFlow(false);
					}}
				/>
			)}

			{showDisableOTPFlow && (
				<DisableOTPDeviceFlow
					onComplete={() => {
						mutate();
						setShowDisableOTPFlow(false);
					}}
					onClose={() => {
						setShowDisableOTPFlow(false);
					}}
				/>
			)}

			{showChangeOTPNumberFlow && (
				<ChangeOTPNumberFlow
					onClose={() => {
						setShowChangeOTPNumberFlow(false);
					}}
					onComplete={() => {
						mutate();
						setShowChangeOTPNumberFlow(false);
					}}
				/>
			)}
		</>
	);
};
