import type {
	CreateRecipient,
	CreateRecipientAddress,
	CreateRecipientBankDetails,
	UpdateRecipient,
	UpdateRecipientAddress,
	UpdateRecipientBankDetails,
} from "@app/entities";
import { getMappedReasons } from "@app/utils";

import { getReasons } from "@app/utils/get-reasons";
import { api } from "./api";
import {
	type CreateRecipientAddressRequest,
	type CreateRecipientBankDetailsRequest,
	type GenericFailureResponse,
	type UpdateRecipientAddressRequest,
	type UpdateRecipientBankDetailsRequest,
	type UpdateRecipientRequest,
} from "./models";

export async function createRecipient(
	clientId: number,
	recipient: CreateRecipient,
) {
	try {
		await api.post(`clients/${clientId}/recipients/`, {
			address: recipient.address
				? mapToCreateRecipientAddressRequest(recipient.address)
				: {},
			bank_details: recipient.bankDetails
				? mapToCreateRecipientBankDetailsRequest(recipient.bankDetails)
				: {},
			currencies: recipient.currencies,
			entity_type: recipient.entityType,
			first_name: recipient.firstName,
			last_name: recipient.lastName,
			legal_entity_name: recipient.legalEntityName,
			is_client: recipient.isClient,
		});
	} catch (exception: any) {
		const error = exception.data as GenericFailureResponse;
		const reasons = getReasons(exception);
		const mappedReasons = getMappedReasons(error);
		return {
			reasons,
			mappedReasons,
		};
	}
}

export async function updateRecipient(recipient: UpdateRecipient) {
	try {
		await api.put(
			`recipients/${recipient.recipientId}/`,
			mapToUpdateRecipientRequest(recipient),
		);
	} catch (exception: any) {
		const error = exception.data as GenericFailureResponse;
		const reasons = getReasons(exception);
		const mappedReasons = getMappedReasons(error);
		return {
			reasons,
			mappedReasons,
		};
	}
}

export async function setRecipientNickname(
	recipientId: number,
	nickname: string,
) {
	try {
		const { status } = await api.put(
			`recipients/${recipientId}/update_nickname/`,
			{
				nickname: nickname,
			},
		);

		return status;
	} catch (exception: any) {
		const error = exception.data as GenericFailureResponse;
		const reasons = getReasons(exception);
		const mappedReasons = getMappedReasons(error);

		return {
			reasons,
			mappedReasons,
		};
	}
}

export async function deleteRecipient(recipientId: number) {
	try {
		const { status } = await api.delete(`recipients/${recipientId}/`);
		return status;
	} catch (exception: any) {
		const reasons = getReasons(exception);
		return {
			reasons,
		};
	}
}

function mapToCreateRecipientAddressRequest(
	value: CreateRecipientAddress,
): CreateRecipientAddressRequest {
	return {
		address_line_1: value.addressLine1,
		address_line_2: value.addressLine2,
		city: value.city,
		country: value.country,
		postal_code: value.postalCode,
		province: value.province,
	};
}

function mapToCreateRecipientBankDetailsRequest(
	value: CreateRecipientBankDetails,
): CreateRecipientBankDetailsRequest {
	return {
		account_number: value.accountNumber,
		routing_number: value.routingNumber,
		sort_code: value.sortCode,
		swift_code: value.swiftCode,
	};
}

function mapToUpdateRecipientRequest(
	value: UpdateRecipient,
): UpdateRecipientRequest {
	return {
		address: value.address
			? mapToUpdateRecipientAddressRequest(value.address)
			: {},
		bank_details: value.bankDetails
			? mapToUpdateRecipientBankDetailsRequest(value.bankDetails)
			: {},
		currencies: value.currencies,
		entity_type: value.entityType,
		first_name: value.firstName,
		last_name: value.lastName,
		legal_entity_name: value.legalEntityName,
		recipient_id: value.recipientId,
		is_client: value.isClient,
	};
}

function mapToUpdateRecipientAddressRequest(
	value: UpdateRecipientAddress,
): UpdateRecipientAddressRequest {
	return {
		address_line_1: value.addressLine1,
		address_line_2: value.addressLine2,
		city: value.city,
		country: value.country,
		postal_code: value.postalCode,
		province: value.province,
	};
}

function mapToUpdateRecipientBankDetailsRequest(
	value: UpdateRecipientBankDetails,
): UpdateRecipientBankDetailsRequest {
	return {
		account_number: value.accountNumber,
		routing_number: value.routingNumber,
		sort_code: value.sortCode,
		swift_code: value.swiftCode,
	};
}
