import { useAuth } from "@app/hooks/use-auth";
import { useVerifySession } from "@app/wrappers/auth/use-verify-session";
import { useEffect, useRef, useState } from "react";
import { Button } from "../button";
import { Dialog } from "../dialog";
import timeoutClockSrc from "./timeout-clock.svg";

import styles from "./index.module.css";

const THIRTY_SECONDS = 30000;
const DEFAULT_COUNT = 30;

export const SessionTimeout = () => {
	const { onLogout } = useAuth();
	const timeoutRef = useRef<NodeJS.Timeout | null>(null);
	const [countdown, setCountdown] = useState<number>(DEFAULT_COUNT);
	const [showInactiveModal, setShowInactiveModal] = useState(false);
	const { data, mutate } = useVerifySession();

	useEffect(() => {
		if (data?.expires_at) {
			if (timeoutRef.current) {
				setShowInactiveModal(false);
				clearTimeout(timeoutRef.current);
			}
			timeoutRef.current = setTimeout(
				() => {
					setShowInactiveModal(true);
					setCountdown(DEFAULT_COUNT);
				},
				new Date(data.expires_at).getTime() - Date.now() - THIRTY_SECONDS,
			);
		}
	}, [data?.expires_at]);

	useEffect(() => {
		if (showInactiveModal && countdown > 0) {
			const timeoutId = setTimeout(() => {
				setCountdown((prevCount) => prevCount - 1);
			}, 1000);
			return () => clearTimeout(timeoutId);
		}
	}, [showInactiveModal, countdown]);

	useEffect(() => {
		if (countdown <= 0) {
			onLogout("?expired=true");
		}
	}, [countdown, onLogout]);

	return (
		<>
			<Dialog
				isOpen={showInactiveModal}
				actions={
					<>
						<Button
							variant="secondary"
							onClick={() => {
								onLogout("?expired=true");
							}}
						>
							Log out
						</Button>
						<Button
							onClick={() => {
								if (countdown > 0) {
									setShowInactiveModal(false);
									setCountdown(DEFAULT_COUNT);
									mutate();
								} else {
									onLogout("?expired=true");
								}
							}}
						>
							Stay logged in
						</Button>
					</>
				}
			>
				<img
					className={styles.icon}
					src={timeoutClockSrc}
					alt=""
					width={60}
					height={60}
				/>
				<h1 className={styles.title}>Are you still there?</h1>
				<p className={styles.description}>
					You’ve been inactive for a while. For your security, you’ll be logged
					out in{" "}
					<strong>
						{countdown} {countdown === 1 ? "second" : "seconds"}
					</strong>
					.
				</p>
			</Dialog>
		</>
	);
};
