import { Button } from "@app/components/button";
import { CustomLoader } from "@app/components/custom-loader";
import { IconButton } from "@app/components/icon-button";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { pathParams } from "@app/constants/paths";
import { useShowToast } from "@app/helpers";
import { useMediaQuery } from "@app/hooks/use-media-query";
import type { GenericFailureResponse, MappedReasons } from "@app/services";
import { FiTrash2 } from "react-icons/fi";

import PenIcon from "@app/assets/images/pen-icon.svg";
import { DocumentUploadModal } from "@app/components/document-upload-modal";
import { ProfilePicture } from "@app/components/profile-picture";
import { isPWA } from "@app/config/env";
import { useClientProfile } from "@app/hooks/use-client-profile";
import { useProfilePicture } from "@app/hooks/use-profile-picture";
import { useUserSettings } from "@app/hooks/use-user-settings";
import { getMappedReasons } from "@app/utils";
import { Title } from "../onboarding/title";
import {
	ChangeEmailAddressModal,
	ChangeEmailForm,
} from "./change-email-address-modal";
import {
	ChangePasswordForm,
	ChangePasswordModal,
} from "./change-password-modal";
import styles from "./index.module.css";
import { NewEmailNotVerfiedWarning } from "./new-email-not-verified-warning";
import { RemoveProfilePictureModal } from "./remove-profile-picture-modal";
import { useUserProfile } from "./use-user-profile";
import { VerificationLinkSentModal } from "./verification-link-sent-modal";
import { OtpAuthentication } from "./otp-authentication";
import {
	isChangeEmailAddressEnabled,
	isOTPAuthEnabled,
} from "@app/constants/feature-flags";
import { StandardOTPFlow } from "../otp/standard-otp-flow";
import { getIsOTPError } from "../otp/get-is-otp-error";
import { ConfirmedModal } from "../otp/modals/confirmed-modal";
import { AxiosProgressEvent } from "axios";

export const UserSettingsContent = ({
	variant = "default",
}: {
	variant?: "default" | "onboarding";
}) => {
	const [showStandardOTPFlow, setShowStandardOTPFlow] = useState(false);
	const [tempChangePasswordForm, setTempChangePasswordForm] =
		useState<ChangePasswordForm | null>(null);
	const [hasCheckedEmailChange, sethasCheckedEmailChange] = useState(false);
	const [isSubmitting, setSubmitting] = useState(false);
	const isMobile = useMediaQuery();
	const [showVerificationLinkSentModal, setShowVerificationLinkSentModal] =
		useState(false);

	const {
		data: userProfile,
		isLoading: isUserProfileLoading,
		mutate: userProfileMutate,
	} = useUserProfile();

	const {
		data: profilePicture,
		mutate: mutateProfilePicture,
		updateProfilePicture,
	} = useProfilePicture();
	const {
		data: clientProfile,
		isLoading: isClientProfileLoading,
		mutate: clientProfileMutate,
	} = useClientProfile();
	const { changeEmail, changePassword, sendChangeEmailLink } =
		useUserSettings();

	const [showToast] = useShowToast();

	const params = useParams();
	const paramToken = params[pathParams.token];

	const isLoading =
		isSubmitting || isUserProfileLoading || isClientProfileLoading;

	const [changeEmailPassword, setChangeEmailPassword] = useState<
		string | undefined
	>(undefined);
	const [showPasswordConfirmationModal, setShowPasswordConfirmationModal] =
		useState(false);
	const [showChangeEmailModal, setShowChangeEmailModal] = useState(false);
	const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
	const [showChangeProfilePictureModal, setShowChangeProfilePictureModal] =
		useState(false);
	const [showRemoveProfilePictureModal, setShowRemoveProfilePictureModal] =
		useState(false);

	const [emailMappedReasons, setEmailMappedReasons] = useState<MappedReasons>();
	const [passwordMappedReasons, setPasswordMappedReasons] =
		useState<MappedReasons>();

	const onToggleChangeEmailModal = () =>
		setShowChangeEmailModal(!showChangeEmailModal);

	const onToggleChangePasswordModal = () =>
		setShowChangePasswordModal((current) => !current);

	const onToggleChangeProfilePictureModal = () =>
		setShowChangeProfilePictureModal(!showChangeProfilePictureModal);
	const onToggleRemoveProfilePictureModal = () =>
		setShowRemoveProfilePictureModal(!showRemoveProfilePictureModal);

	const handleChangePassword = async (
		passwords: ChangePasswordForm,
		initialSubmitting = true,
	) => {
		if (!passwords.password || !passwords.newPassword) return;
		setTempChangePasswordForm(passwords);
		setPasswordMappedReasons(undefined);
		setSubmitting(initialSubmitting);

		const errors = await changePassword(
			passwords.password,
			passwords.newPassword,
		);
		setSubmitting(false);

		if (errors) {
			if (getIsOTPError(errors.apiErrors)) {
				setShowStandardOTPFlow(true);
				setShowChangePasswordModal(false);
				return;
			}

			setPasswordMappedReasons(errors.mappedReasons);
			return;
		}

		setShowChangePasswordModal(false);
		setShowStandardOTPFlow(false);
		if (isOTPAuthEnabled) {
			setShowPasswordConfirmationModal(true);
		} else {
			showToast("Password successfully changed", "success");
		}
	};

	const onRemoveProfilePicture = async () => {
		const errors = await updateProfilePicture(undefined);
		if (!errors) {
			onToggleRemoveProfilePictureModal();
			userProfileMutate();
			mutateProfilePicture();
			clientProfileMutate();
		}
	};

	const handleSendEmailLink = async (
		data: ChangeEmailForm,
		isResend = false,
	) => {
		if (!data.email) return false;
		try {
			setEmailMappedReasons(undefined);
			setSubmitting(true);
			await sendChangeEmailLink(data.email, data.password);
			if (data.password) {
				setChangeEmailPassword(data.password);
			}
			setShowChangeEmailModal(false);
			if (!isResend) {
				setShowVerificationLinkSentModal(true);
			}
			userProfileMutate();
		} catch (error: any) {
			const mappedReasons = getMappedReasons(
				error.data as GenericFailureResponse,
			);
			setEmailMappedReasons(mappedReasons);
			return false;
		} finally {
			setSubmitting(false);
		}
		return true;
	};

	const onUpload = async (
		index: number,
		file: File | undefined,
		onSetUploadComplete: (index: number, id?: number | string) => void,
		onSetUploadProgress: (progressEvent: AxiosProgressEvent) => void,
		onUploadError: (error: string, index: number) => void,
	) => {
		if (file) {
			const errors = await updateProfilePicture(file, onSetUploadProgress);

			if (errors) {
				const errorMessage =
					errors.apiErrors?.[0] ??
					errors.fieldErrors?.[0]?.message ??
					"Upload failed";
				onUploadError(errorMessage, index);
			}
			onSetUploadComplete(index);
			mutateProfilePicture();
			clientProfileMutate();
		}
	};

	const handleChangeEmail = useCallback(
		async (paramToken: string) => {
			sethasCheckedEmailChange(true);
			try {
				await changeEmail(paramToken);
				showToast("Email successfully changed", "success");
				userProfileMutate();
			} catch {
				showToast("Failed to change email", "error");
			}
		},
		[changeEmail, showToast, userProfileMutate],
	);

	useEffect(() => {
		if (paramToken && !hasCheckedEmailChange) {
			handleChangeEmail(paramToken);
		}
	}, [paramToken, handleChangeEmail, hasCheckedEmailChange]);

	return (
		<>
			<div data-variant={variant} className={styles.container}>
				{variant === "onboarding" ? (
					isMobile ? null : (
						<Title>Settings</Title>
					)
				) : (
					<h2 className={styles.title}>Settings</h2>
				)}

				<div className={styles.pictureRow}>
					<div className={styles.pictureRowInner}>
						<ProfilePicture size={100} src={profilePicture?.base64} />
						<div className={styles.pictureDescription}>
							<p>Profile Picture</p>
							<p className={styles.pictureFormat}>PNG, JPEG under 10MB</p>
						</div>
					</div>

					<div className={styles.pictureActions}>
						<Button
							disabled={isLoading}
							onClick={() => {
								onToggleChangeProfilePictureModal();
							}}
						>
							{clientProfile?.profile_picture_url
								? "Change picture"
								: "Add picture"}
						</Button>
						<IconButton
							disabled={isLoading}
							onClick={onToggleRemoveProfilePictureModal}
						>
							<FiTrash2 size={24} />
						</IconButton>
					</div>
				</div>
				<div className={styles.divider} />

				<div className={styles.settingsSection}>
					<h3 className={styles.subtitle}>User Settings</h3>

					<div className={styles.settingsRow}>
						<div>
							<p className={styles.settingsLabel}>Email</p>
							<p className={styles.settingsValue}>
								{userProfile?.pending_email_change
									? userProfile?.pending_email_change
									: userProfile?.email}
							</p>
						</div>
						{isMobile ? (
							<IconButton onClick={onToggleChangeEmailModal}>
								<img src={PenIcon} alt="Edit icon" />
							</IconButton>
						) : (
							<Button
								className={styles.settingsButton}
								variant="secondary"
								onClick={onToggleChangeEmailModal}
							>
								Change email
							</Button>
						)}
					</div>

					{userProfile?.pending_email_change && (
						<NewEmailNotVerfiedWarning
							email={userProfile.pending_email_change}
							onResend={
								changeEmailPassword || !isChangeEmailAddressEnabled
									? () =>
											handleSendEmailLink(
												{
													email: userProfile.pending_email_change,
													password: changeEmailPassword,
												},
												true,
											)
									: undefined
							}
						/>
					)}

					<div className={styles.settingsRow}>
						<div>
							<p className={styles.settingsLabel}>Password</p>
							<p className={styles.settingsValue}>••••••••••••••</p>
						</div>
						{isMobile ? (
							<IconButton onClick={onToggleChangePasswordModal}>
								<img src={PenIcon} alt="Edit icon" />
							</IconButton>
						) : (
							<Button
								className={styles.settingsButton}
								onClick={onToggleChangePasswordModal}
								variant="secondary"
							>
								Change password
							</Button>
						)}
					</div>

					{isOTPAuthEnabled && <OtpAuthentication />}

					{isPWA && (
						<div className={styles.settingsRow}>
							<div>
								<p className={styles.settingsLabel}>Delete account</p>
								<p className={styles.settingsValue}>
									Send us a request to delete your account
								</p>
							</div>
							{isMobile ? (
								<IconButton
									onClick={() => {
										window.open(
											"https://futureforex.co.za/account-deletion",
											"_blank",
										);
									}}
								>
									<FiTrash2 />
								</IconButton>
							) : (
								<Button
									className={styles.settingsButton}
									href="https://futureforex.co.za/account-deletion"
									variant="secondary"
								>
									Delete account
								</Button>
							)}
						</div>
					)}
				</div>
			</div>

			<ChangeEmailAddressModal
				isOpen={showChangeEmailModal}
				onClose={onToggleChangeEmailModal}
				onSendVerification={handleSendEmailLink}
				mappedReasons={emailMappedReasons}
			/>
			<ChangePasswordModal
				isOpen={showChangePasswordModal}
				onClose={onToggleChangePasswordModal}
				onSave={handleChangePassword}
				mappedReasons={passwordMappedReasons}
			/>
			{showChangeProfilePictureModal && (
				<DocumentUploadModal
					multiple={false}
					maxFiles={1}
					showSingleButton
					onClose={() => {
						onToggleChangeProfilePictureModal();
					}}
					onUpload={onUpload}
					title="Upload image"
					variant="profile"
					onConfirmUpload={onToggleChangeProfilePictureModal}
				/>
			)}
			{showRemoveProfilePictureModal && (
				<RemoveProfilePictureModal
					onClose={onToggleRemoveProfilePictureModal}
					onRemove={onRemoveProfilePicture}
					isOpen={showRemoveProfilePictureModal}
				/>
			)}
			<VerificationLinkSentModal
				email={userProfile?.pending_email_change}
				isOpen={showVerificationLinkSentModal}
				onClose={() => setShowVerificationLinkSentModal(false)}
			/>

			{showStandardOTPFlow && (
				<StandardOTPFlow
					onClose={() => setShowStandardOTPFlow(false)}
					onComplete={async () => {
						if (tempChangePasswordForm) {
							await handleChangePassword(tempChangePasswordForm, false);
						}
					}}
				/>
			)}

			{showPasswordConfirmationModal && (
				<ConfirmedModal
					hasMinWidth={false}
					title="Password changed"
					onClose={() => {
						setShowPasswordConfirmationModal(false);
					}}
				/>
			)}

			{isLoading && <CustomLoader />}
		</>
	);
};
