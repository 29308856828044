import {
	type ConfirmQuote,
	type ConfirmQuoteReturn,
	type GetExchangeDetails,
	type GetSettlementAccountSingular,
	type GetSettlementAccounts as GetSettlementAccountsModel,
} from "@app/entities";
import { concatenateArrays } from "@app/utils";

import { getReasons } from "@app/utils/get-reasons";
import { api } from "./api";
import type {
	ConfirmQuoteRequest,
	ConfirmQuoteResponse,
	GenericFailureResponse,
	GenericResponse,
	GetSettlementAccountSingularResponse,
	GetSettlementAccountsResponse,
	MappedReasons,
} from "./models";

export interface FieldInvalidationResponse {
	detail?: FieldInvalidationDetailResponse[];
}

interface FieldInvalidationDetailResponse {
	loc: string[];
	msg?: string;
	type?: string;
}

interface GetExchangeDetailsResponse {
	transaction_id: number;
	transaction_date: string;
	fx_amount: string;
	reference_id: string;
	zar_amount: string;
	currency_code: string;
	direction: string;
	payment_ids: number[];
	exchange_rate_components: {
		fx_component: string;
		zar_component: string;
	};
	spread: string;
	settlement_account: string;
	deal_confirmation_ready: boolean;
	fec: {
		is_fec: boolean;
		start_date: string;
		end_date: string;
		maturity_date: string;
		fec_type: string;
	};
	exchange_rate: string;
}

export async function getExchangeDetails(
	transactionId: number,
): Promise<GetExchangeDetails | GenericResponse> {
	try {
		const { data } = await api.get(
			`transactions/${transactionId}/exchange-details/`,
		);
		return mapToExchangeDetailsObject(data);
	} catch (exception: any) {
		const reasons = getReasons(exception);
		return {
			reasons,
		};
	}
}

export async function confirmQuote(
	quoteId: number,
	quoteBody: ConfirmQuote,
): Promise<ConfirmQuoteReturn | GenericResponse> {
	try {
		const { data } = await api.post(
			`transactions/quotes/${quoteId}/confirm/`,
			mapToConfirmQuote(quoteBody),
		);

		return mapToConfirmQuoteResponse(data);
	} catch (exception: any) {
		const error = exception.data as GenericFailureResponse;
		const fieldReasons: FieldInvalidationDetailResponse[] | undefined =
			Array.isArray(error.detail)
				? (error as FieldInvalidationResponse).detail
				: undefined;

		const mappedReasons: MappedReasons | undefined = fieldReasons
			? {
					valueDate:
						fieldReasons
							?.filter((x) => x.loc.includes("value_date"))
							?.map((x) => x.msg || "") || [],
				}
			: undefined;

		return {
			reasons: [],
			mappedReasons,
		};
	}
}

export async function GetSettlementAccounts(
	clientId: number,
): Promise<GetSettlementAccountsModel | GenericResponse> {
	try {
		const { data } = await api.get<GetSettlementAccountsResponse>(
			`clients/${clientId}/forex-quote/options/`,
		);
		return mapToGetSettlementAccounts(data);
	} catch (exception: any) {
		const error = exception.data as GenericFailureResponse;
		const reasons = concatenateArrays([(error.detail as string) ?? ""]);
		return {
			reasons,
		};
	}
}

function mapToConfirmQuote(value: ConfirmQuote): ConfirmQuoteRequest {
	return {
		value_date: value.valueDate,
	};
}
function mapToGetSettlementAccounts(
	value: GetSettlementAccountsResponse,
): GetSettlementAccountsModel {
	return {
		settlementAccounts: value.settlement_accounts.map((x) =>
			mapToGetSettlementAccountSingular(x),
		),
	};
}

function mapToGetSettlementAccountSingular(
	value: GetSettlementAccountSingularResponse,
): GetSettlementAccountSingular {
	return {
		accountNumber: value.account_number,
		accountType: value.account_type,
		bankAccountId: value.bank_account_id,
	};
}
function mapToConfirmQuoteResponse(
	value: ConfirmQuoteResponse,
): ConfirmQuoteReturn {
	return {
		transactionId: value.transaction_id,
	};
}

function mapToExchangeDetailsObject(
	value: GetExchangeDetailsResponse,
): GetExchangeDetails {
	return {
		currencyCode: value.currency_code,
		dealConfirmationReady: value.deal_confirmation_ready,
		direction: value.direction,
		paymentIds: value.payment_ids,
		exchangeRateComponents: {
			fxComponent: value.exchange_rate_components.fx_component,
			zarComponent: value.exchange_rate_components.zar_component,
		},
		fec: {
			endDate: value.fec.end_date,
			fecType: value.fec.fec_type,
			isFec: value.fec.is_fec,
			maturityDate: value.fec.maturity_date,
			startDate: value.fec.start_date,
		},
		fxAmount: value.fx_amount,
		settlementAccount: value.settlement_account,
		spread: value.spread,
		transactionDate: value.transaction_date,
		referenceId: value.reference_id,
		transactionId: value.transaction_id,
		zarAmount: value.zar_amount,
		exchangeRate: value.exchange_rate,
	};
}
