import { useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { Button } from "@app/components/button";
import { FadeIn } from "@app/components/fade-in";
import { paths } from "@app/constants/paths";

import { ApiErrors } from "@app/components/api-errors";
import { Dialog } from "@app/components/dialog";
import { Title } from "@app/components/title";
import { WarningNote } from "@app/components/warning-note";
import { isLocalhost, isPWA } from "@app/config/env";
import { useMediaQuery } from "@app/hooks/use-media-query";
import { isValidEmail } from "../is-valid-email";
import { PasswordToggleButton } from "../password-toggle-button";
import { TextField } from "../text-field";
import { EmailIcon } from "./email-icon";
import styles from "./index.module.css";
import { LockIcon } from "./lock-icon";
import { useLogin } from "./use-login";
import { isOTPAuthEnabled } from "@app/constants/feature-flags";
import { getOTPDetails, OTPMethod } from "@app/features/otp/use-otp";
import { StandardOTPFlow } from "@app/features/otp/standard-otp-flow";
import { getIsOTPError } from "@app/features/otp/get-is-otp-error";
import { STORAGE_KEYS } from "@app/constants/storage-keys";
import { FormErrors } from "@app/utils/get-form-errors";
import { LoginSelection } from "./login-selection";
import { getIsIgnoredPath } from "./get-is-ignored-path";

const loginErrors = {
	unverifiedAccount: "Please confirm your email with a link that we sent you.",
	arbInactiveAccount: "User inactive or deleted.",
};

const Login = () => {
	const [showStandardOtpFlow, setShowStandardOtpFlow] = useState(false);
	const [isUnverifiedArbAccount, setIsUnverifiedArbAccount] = useState(false);
	const [showLoginSelection, setShowLoginSelection] = useState(false);
	const { onForexLogin, onArbLogin, onGoToArb, onValidateForexLogin } =
		useLogin();
	const [searchParams] = useSearchParams();
	const [showVerfiedDialog, setShowVerifiedDialog] = useState(
		searchParams.get("verified") === "true",
	);
	const recentUsername =
		isPWA || isLocalhost
			? window.localStorage.getItem(STORAGE_KEYS.recentUsername)
			: undefined;
	const username = searchParams.get("email") ?? recentUsername ?? "";
	const isExpired = searchParams.get("expired") === "true";
	const [isLoading, setIsLoading] = useState(false);

	const [showPassword, setShowPassword] = useState(false);
	const isMobile = useMediaQuery();
	const navigate = useNavigate();

	const {
		getValues,
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<{
		username: string;
		password: string;
	}>({
		defaultValues: {
			username,
		},
		mode: "onBlur",
	});

	const location = useLocation();
	const [apiErrors, setApiErrors] = useState<Array<string>>(
		location.state?.errorMessage ? [location.state.errorMessage] : [],
	);

	const handleForgotPassword = () => navigate(paths().forgotPassword);

	const handleNavigateToPlatform = (otp?: "init" | "trust") => {
		const redirect = window.sessionStorage.getItem(STORAGE_KEYS.redirect);
		window.sessionStorage.removeItem(STORAGE_KEYS.redirect);

		const isIgnoredPath = getIsIgnoredPath(redirect);
		const shouldRedirect = redirect && !isIgnoredPath && !otp;

		window.location.href = shouldRedirect
			? redirect
			: `${paths().root}${otp !== undefined ? `?otp=${otp}` : ""}`;
	};

	const handleOTPCheck = async (isPostOTPAuth = false) => {
		if (!isOTPAuthEnabled) {
			handleNavigateToPlatform();
			return;
		}
		try {
			const { data } = await getOTPDetails();
			if (data.status === "unknown") {
				handleNavigateToPlatform("init");
				return;
			}
			handleNavigateToPlatform(isPostOTPAuth ? "trust" : undefined);
		} catch (error) {
			console.error(error);
			handleNavigateToPlatform();
		}
	};

	const handleLogin = async (
		values: {
			username: string;
			otp_token?: string;
			otp_method?: OTPMethod;
			password: string;
		},
		isPostOTPAuth?: boolean,
	) => {
		setIsLoading(true);
		const [, forexErrors] = await onForexLogin(values);
		setIsLoading(false);
		const hasForexErrors = forexErrors && forexErrors.length > 0;
		const hasOTPError = forexErrors && getIsOTPError(forexErrors);

		// Handle the case of forex login having errors
		if (hasForexErrors) {
			if (hasOTPError) {
				setShowStandardOtpFlow(true);
				return;
			}

			const isUnverifiedForexAccount =
				forexErrors.indexOf(loginErrors.unverifiedAccount) > -1;
			if (isUnverifiedForexAccount) {
				window.localStorage.setItem(
					STORAGE_KEYS.unverifiedEmail,
					values.username,
				);
				navigate(paths().notVerified);
			} else {
				if (isPostOTPAuth) {
					return forexErrors;
				}
				setApiErrors(forexErrors);
			}
			return;
		}

		handleOTPCheck(isPostOTPAuth);
	};

	const handleSubmitForm = async (values: {
		username: string;
		password: string;
	}) => {
		window.localStorage.removeItem(STORAGE_KEYS.refName);
		window.localStorage.setItem(STORAGE_KEYS.recentUsername, values.username);

		setIsLoading(true);
		const isForexLoginValid = await onValidateForexLogin(values);
		const arbErrors = await onArbLogin(values);
		setIsLoading(false);

		const hasArbErrors = arbErrors && arbErrors.length > 0;

		// Arb login success and valid forex user found
		if (!hasArbErrors && isForexLoginValid) {
			const isAddClient =
				window.sessionStorage.getItem(STORAGE_KEYS.arbAddClient) === "true";
			if (isAddClient) {
				onGoToArb();
				return;
			}

			setShowLoginSelection(true);
			return;
		}

		// Arb login success, but no valid forex user found
		if (!hasArbErrors && !isForexLoginValid) {
			onGoToArb();
			return;
		}

		// Handle case of unverified arb account
		const isInactiveAccountError =
			arbErrors.length === 1 &&
			arbErrors[0].includes(loginErrors.arbInactiveAccount);

		if (isInactiveAccountError) {
			window.localStorage.setItem(
				STORAGE_KEYS.unverifiedEmail,
				values.username,
			);

			// If the Forex verify check is successful, we want to show the selection modal and only show the
			// unverified screen once the user has selected the arb option
			if (isForexLoginValid) {
				setIsUnverifiedArbAccount(true);
				setShowLoginSelection(true);
			} else {
				navigate(paths().arbitrageNotVerified);
			}
			return;
		}

		// Only Forex login is valid, so we can proceed with the login flow
		handleLogin(values);
	};

	return (
		<>
			<form onSubmit={handleSubmit(handleSubmitForm)} noValidate>
				<>
					<Title>Welcome back</Title>
					{showLoginSelection ? (
						<LoginSelection
							isLoading={isLoading}
							onForexClick={() => handleLogin(getValues())}
							onArbClick={() => {
								if (isUnverifiedArbAccount) {
									navigate(paths().arbitrageNotVerified);
									return;
								}
								onGoToArb();
							}}
						/>
					) : (
						<>
							{isExpired && (
								<WarningNote
									className={styles.warningNote}
									title="You were logged out due to inactivity."
								/>
							)}
							<div className={styles.formContent}>
								<TextField
									register={register}
									name="username"
									label="Email"
									type="email"
									validate={isValidEmail}
									required
									leftSection={<EmailIcon />}
									error={errors.username}
								/>
								<TextField
									register={register}
									name="password"
									label="Password"
									type={showPassword ? "text" : "password"}
									required
									leftSection={<LockIcon />}
									rightSection={
										<PasswordToggleButton
											value={showPassword}
											onChange={setShowPassword}
										/>
									}
									error={errors.password}
								/>
								<FadeIn show={apiErrors.length > 0}>
									<ApiErrors errors={apiErrors} />
								</FadeIn>
							</div>
							<div className={styles.actions}>
								<Button disabled={isLoading} type="submit" block>
									{isLoading ? "Logging in" : "Log in"}
								</Button>
								<Button variant="tertiary" onClick={handleForgotPassword} block>
									Forgot password
								</Button>
							</div>
						</>
					)}
				</>
			</form>

			<Dialog
				isOpen={showVerfiedDialog}
				onClose={() => setShowVerifiedDialog(false)}
				title="Email verified"
				description={
					<>
						{isMobile
							? "Your email has been verified."
							: "Your email has been successfully verified."}
						<br />
						Please log in to complete your account setup.
					</>
				}
			>
				<Button centered onClick={() => setShowVerifiedDialog(false)}>
					Continue to login
				</Button>
			</Dialog>

			{showStandardOtpFlow && (
				<StandardOTPFlow
					variant="unauthed"
					onResend={async (type) => {
						const [, errors] = await onForexLogin({
							...getValues(),
							otp_method: type,
						});
						if (errors && !getIsOTPError(errors)) {
							return {
								apiErrors: errors,
								fieldErrors: [],
							};
						}
					}}
					onClose={() => setShowStandardOtpFlow(false)}
					onComplete={async (token) => {
						if (!token) {
							const [, errors] = await onForexLogin(getValues());
							if (errors && !getIsOTPError(errors)) {
								return {
									apiErrors: errors,
									fieldErrors: [],
								};
							}
							return;
						}

						const errors = await handleLogin(
							{
								otp_token: token,
								...getValues(),
							},
							true,
						);
						if (errors) {
							return {
								apiErrors: errors,
								fieldErrors: [],
							} as FormErrors;
						}
						setShowStandardOtpFlow(false);
					}}
				/>
			)}
		</>
	);
};

export default Login;
