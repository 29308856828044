import {
	GenericFailureResponse,
	type MappedReasons,
	apiRecipients,
} from "@app/services";

import { getMappedReasons } from "@app/utils";
import { getReasons } from "@app/utils/get-reasons";

import type { AppThunk } from "../store";
import {
	setDeleteRecipientError,
	setDeleteRecipientLoading,
	setRecipientId,
	setSetRecipientNicknameError,
	setRecipientIdLoading,
	setSetRecipientNicknameLoading,
} from "./recipients-slice";

export const setRecipientNicknameAction =
	(
		recipientId: number,
		nickname: string,
		callback?: (errors?: string[], mappedReasons?: MappedReasons) => void,
	): AppThunk =>
	async (dispatch) => {
		let reasons: string[] | undefined = undefined;
		let mappedReasons: MappedReasons | undefined;

		try {
			dispatch(setSetRecipientNicknameLoading(true));
			dispatch(setSetRecipientNicknameError(null));

			const response = await apiRecipients.setRecipientNickname(
				recipientId,
				nickname,
			);

			if (typeof response === "object" && "reasons" in response) {
				dispatch(setSetRecipientNicknameError(response.reasons));
				reasons = response.reasons;
			} else {
				return response;
			}
		} catch (exception: any) {
			const error = exception.data as GenericFailureResponse;
			reasons = getReasons(exception);
			mappedReasons = getMappedReasons(error);
			dispatch(setSetRecipientNicknameError(exception));
		} finally {
			if (callback) callback(reasons, mappedReasons);
			dispatch(setSetRecipientNicknameLoading(false));
		}
	};

export const deleteRecipientAction =
	(
		recipientId: number,
		callback?: (response?: number | string[]) => void,
	): AppThunk =>
	async (dispatch) => {
		let results: number | string[] | undefined = undefined;

		try {
			dispatch(setDeleteRecipientLoading(true));
			dispatch(setDeleteRecipientError(null));

			const response = await apiRecipients.deleteRecipient(recipientId);

			if (typeof response === "object" && "reasons" in response) {
				dispatch(setDeleteRecipientError(response.reasons));
				results = response.reasons;
			} else {
				results = response;
			}
		} catch (error: any) {
			dispatch(setDeleteRecipientError(error));
		} finally {
			if (callback) callback(results);
			dispatch(setDeleteRecipientLoading(false));
		}
	};

export const setRecipientIdAction =
	(recipientId?: number): AppThunk =>
	async (dispatch) => {
		try {
			dispatch(setRecipientIdLoading(true));
			dispatch(setRecipientId(recipientId));
		} catch (error: any) {
		} finally {
			dispatch(setRecipientIdLoading(false));
		}
	};
