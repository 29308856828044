import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { paths } from "@app/constants/paths";
import type { ListPaymentBop } from "@app/entities";
import {
	useGetPaymentBop,
	useGetPaymentBopOptions,
	useListPaymentBops,
	useListSuggestedPaymentBops,
	useUpdateBop,
} from "@app/helpers";
import { useSetCurrentPage } from "@app/hooks/use-set-payment-current-page";
import { useStateWithCallback } from "@app/hooks/use-state-with-callback";
import type { RootState } from "@app/redux";

import { BalanceOfPaymentView } from "./balance-of-payment-view";

import { useAccountManager } from "@app/hooks/use-account-manager";
import { tempPaymentStatusMapping } from "@app/hooks/use-payment-status";
import { useSupportingDocuments } from "@app/hooks/use-supporting-documents";
import { useTransaction } from "@app/hooks/use-transaction";
import { useTransactionId } from "@app/hooks/use-transaction-id";
import type { PaymentBopQueryParams } from "@app/services";
import { useSubmittedTransactionRedirect } from "../use-submitted-transaction-redirect";
import { handleGeneralError } from "@app/utils/handle-general-error";
import { useMediaQuery } from "@app/hooks/use-media-query";
interface BalanceOfPaymentState {
	existingBopOption?: number;
	selectedBopOption?: number;
	queryParams: PaymentBopQueryParams;
}

interface BalanceOfPaymentLogicState {
	bopChangedNavigationPath?: string;
	currentPage?: number;
	showBopChangedModal?: boolean;
	showUnselectedModal?: boolean;
}

const tableId = "bop-table";
const selectedRowClassName = "selected-row";

export const BalanceOfPayment = () => {
	const [isSaving, setIsSaving] = useState(false);
	const navigate = useNavigate();
	const isMobile = useMediaQuery();
	const timeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);
	const [getPaymentBopHook] = useGetPaymentBop();
	const [getPaymentBopOptionsHook] = useGetPaymentBopOptions();
	const [listPaymentBopsHook] = useListPaymentBops();
	const [listSuggestedPaymentBopsHook] = useListSuggestedPaymentBops();
	const [updateBopHook] = useUpdateBop();

	const { data: accountManager } = useAccountManager();
	const transactionId = useTransactionId();
	const {
		exchangeDetails,
		paymentStatus,
		activePaymentId,
		isPaymentStatusLoading,
		isExchangeDetailsLoading,
		mutatePaymentStatus,
	} = useTransaction(transactionId);
	const {
		data: paymentSupportingDocs,
		isLoading: isSupportingDocumentsLoading,
	} = useSupportingDocuments(activePaymentId);

	useSubmittedTransactionRedirect();
	useSetCurrentPage(exchangeDetails?.payment_ids[0], "bop");

	const {
		listPaymentBops,
		listPaymentBopsCount,
		listPaymentBopsLoading,
		listSuggestedPaymentBops,
		listSuggestedPaymentBopsLoading,
		paymentBopDetailsLoading,
		paymentBopOptions,
		paymentBopOptionsLoading,
		paymentBopDetails,
	} = useSelector((rootState: RootState) => rootState.payments);

	const hasUploadedDocuments = paymentSupportingDocs?.some(
		(type) => type?.documents?.length > 0,
	);

	const defaultState: BalanceOfPaymentState = {
		existingBopOption: undefined,
		selectedBopOption: undefined,
		queryParams: {
			payment_bop: {
				limit: 10,
				offset: 0,
			},
			payment_id: undefined,
		},
	};
	const [state, setState] = useState<BalanceOfPaymentState>(defaultState);

	const [bopPendingConfirmation, setBopPendingConfirmation] = useState<
		number | undefined
	>(undefined);

	const defaultLogicState: BalanceOfPaymentLogicState = {
		bopChangedNavigationPath: "",
		currentPage: undefined,
		showBopChangedModal: false,
		showUnselectedModal: false,
	};
	const [logicState, setLogicState] =
		useStateWithCallback<BalanceOfPaymentLogicState>(defaultLogicState);

	const [bopCategories, setBopCategories] = useState<string[] | undefined>(
		undefined,
	);

	const getListPaymentBops = () => {
		if (exchangeDetails) {
			const queryParams = state.queryParams;
			queryParams.payment_id = exchangeDetails?.payment_ids[0];

			listPaymentBopsHook(
				queryParams,
				(response?: ListPaymentBop | string[]) => {
					if (!response || Array.isArray(response)) {
						handleGeneralError(response);
					}
				},
			);
		}
	};

	const onBack = () => {
		if (!transactionId) return;
		navigate(paths().paymentDetails(transactionId));
	};

	const onBopChangedBack = () => {
		setLogicState({
			...logicState,
			bopChangedNavigationPath: "",
			showBopChangedModal: false,
		});
	};

	const onBopChangedConfirm = () => {
		if (!exchangeDetails) {
			handleGeneralError();
			return;
		}

		if (!bopPendingConfirmation) return;

		setState({ ...state, selectedBopOption: bopPendingConfirmation });
		setIsSaving(true);
		updateBopHook(
			{
				paymentId: exchangeDetails.payment_ids[0],
				bop: {
					bopId: bopPendingConfirmation,
				},
			},
			() => {
				setLogicState({
					...logicState,
					bopChangedNavigationPath: "",
					showBopChangedModal: false,
				});
				setIsSaving(false);
				mutatePaymentStatus();
				setBopPendingConfirmation(undefined);
			},
		);
	};

	const onChangeCategory = (selectedBopCategory?: string) => {
		let bopCategoryGroup = selectedBopCategory;
		if (bopCategoryGroup === "All") {
			bopCategoryGroup = undefined;
		}
		setState({
			...state,
			queryParams: {
				...state.queryParams,
				payment_bop: {
					...state.queryParams.payment_bop,
					bop_category_group: bopCategoryGroup,
				},
			},
		});
	};

	const onChangeFilterString = (
		filterString?: string,
		immediateSearch?: boolean,
	) => {
		const newState = { ...state };
		newState.queryParams.payment_bop.search_query = filterString ?? "";

		if (filterString === "") {
			newState.queryParams.payment_bop.bop_category_group = undefined;
			setLogicState({ ...logicState, currentPage: 1 });
			getListPaymentBops();
		}

		if (immediateSearch) {
			setLogicState({ ...logicState, currentPage: 1 });
			if (timeoutRef.current) clearTimeout(timeoutRef.current);
			timeoutRef.current = setTimeout(() => getListPaymentBops(), 300);
		}
		setState(newState);
	};

	const onChangeSelected = (selectedBopOption?: number) => {
		if (!exchangeDetails) {
			handleGeneralError();
			return;
		}

		if (!selectedBopOption) return;

		const isUpdatedBoP =
			state.existingBopOption !== undefined &&
			selectedBopOption !== state.existingBopOption &&
			hasUploadedDocuments;
		if (isUpdatedBoP) {
			setBopPendingConfirmation(selectedBopOption);
			setLogicState({
				...logicState,
				showBopChangedModal: true,
				bopChangedNavigationPath: "",
			});
		} else {
			setState({ ...state, selectedBopOption });
			setIsSaving(true);
			updateBopHook(
				{
					paymentId: exchangeDetails.payment_ids[0],
					bop: {
						bopId: selectedBopOption,
					},
				},
				() => {
					setIsSaving(false);
					mutatePaymentStatus();
				},
			);
		}
	};

	const onCloseUnselectedModal = () => {
		setLogicState({ ...logicState, showUnselectedModal: false });
	};

	const onNext = (navigationPath?: string) => {
		if (!transactionId) return;
		const nextNavigationPath =
			navigationPath ?? paths().documents(transactionId);

		if (!exchangeDetails) {
			handleGeneralError();
			return;
		}

		if (nextNavigationPath.includes(paths().paymentDetails(transactionId))) {
			navigate(nextNavigationPath);
			return;
		}

		if (state.selectedBopOption) {
			navigate(nextNavigationPath);
		} else {
			setLogicState({ ...logicState, showUnselectedModal: true });
		}
	};

	const onPageChange = (page: number, rowsPerPage: number) => {
		setState({
			...state,
			queryParams: {
				...state.queryParams,
				payment_bop: {
					...state.queryParams.payment_bop,
					limit: rowsPerPage,
					offset: (page - 1) * rowsPerPage,
				},
			},
		});

		setLogicState({ ...logicState, currentPage: page });
	};

	const onSearch = () => {
		setLogicState({ ...logicState, currentPage: 1 }, () => {
			getListPaymentBops();
		});
	};

	const handleResumeLater = async () => {
		return new Promise<void>((resolve) => {
			if (!state.selectedBopOption || !exchangeDetails) return;
			updateBopHook(
				{
					paymentId: exchangeDetails.payment_ids[0],
					bop: {
						bopId: state.selectedBopOption,
					},
				},
				() => {
					resolve(undefined);
				},
			);
		});
	};

	const onSortByName = (directionString?: string) => {
		setState({
			...state,
			queryParams: {
				...state.queryParams,
				payment_bop: {
					...state.queryParams.payment_bop,
					ordering: directionString,
				},
			},
		});
	};

	const scrollToSelected = () => {
		const selectedRowElement =
			document.getElementsByClassName(selectedRowClassName);
		const topPos = (selectedRowElement?.item(0) as HTMLElement)?.offsetTop ?? 0;

		const tableElement = document
			.getElementById(tableId)
			?.getElementsByClassName("p-datatable-wrapper");
		const headerElement = document
			.getElementById(tableId)
			?.getElementsByClassName("p-datatable-thead");
		const headerHeight = headerElement?.item(0)?.clientHeight ?? 0;

		if (tableElement) {
			const firstElement = tableElement?.item(0);
			if (firstElement) {
				firstElement.scrollTop = topPos - headerHeight;
			}
		}
	};

	useEffect(() => {
		if (!isExchangeDetailsLoading && !exchangeDetails) {
			handleGeneralError();
			return;
		}
		if (exchangeDetails) {
			const paymentIdToUse = exchangeDetails.payment_ids[0];
			listSuggestedPaymentBopsHook(paymentIdToUse, () => {
				getPaymentBopHook(paymentIdToUse, (response) => {
					if (response && !Array.isArray(response)) {
						setState({
							...state,
							existingBopOption: response.id,
							selectedBopOption: response.id,
							queryParams: {
								...state.queryParams,
								payment_id: paymentIdToUse,
							},
						});
						scrollToSelected();
					}
				});
			});

			getPaymentBopOptionsHook(paymentIdToUse);
		}
	}, [exchangeDetails, isExchangeDetailsLoading, navigate]);

	useEffect(() => {
		if (exchangeDetails) {
			getListPaymentBops();
		}
	}, [
		exchangeDetails,
		state.queryParams.payment_bop.limit,
		state.queryParams.payment_bop.offset,
		state.queryParams.payment_bop.ordering,
	]);

	useEffect(() => {
		setLogicState({ ...logicState, currentPage: 1 }, () => {
			getListPaymentBops();
		});
	}, [state.queryParams.payment_bop.bop_category_group]);

	useEffect(() => {
		setBopCategories(paymentBopOptions?.listBopCategoryGroups);
	}, [paymentBopOptions]);

	const isLoading =
		listSuggestedPaymentBopsLoading ||
		paymentBopOptionsLoading ||
		isPaymentStatusLoading ||
		paymentBopDetailsLoading ||
		isSupportingDocumentsLoading;

	return (
		<BalanceOfPaymentView
			currentPage={logicState.currentPage}
			filterString={state.queryParams.payment_bop.search_query}
			listPaymentBops={listPaymentBops}
			loading={isLoading}
			loadingTable={listPaymentBopsLoading}
			popularBopSuggestions={listSuggestedPaymentBops?.popular}
			recentBopSuggestions={listSuggestedPaymentBops?.recentlyUsed}
			selectedBopCategory={state.queryParams.payment_bop.bop_category_group}
			selectedBopOption={state.selectedBopOption}
			existingBopOption={state.existingBopOption}
			selectedRowClassName={selectedRowClassName}
			showBopChangedModal={logicState.showBopChangedModal}
			showUnselectedModal={logicState.showUnselectedModal}
			paymentBopCategories={
				state.queryParams.payment_bop.bop_category_group && !isMobile
					? ["All", ...(bopCategories ?? [])]
					: bopCategories
			}
			paymentStatus={
				paymentStatus ? tempPaymentStatusMapping(paymentStatus) : undefined
			}
			tableId={tableId}
			tableTotal={listPaymentBopsCount ?? 0}
			paymentBopDetails={paymentBopDetails}
			isSaving={isSaving}
			onBack={onBack}
			onBopChangedBack={onBopChangedBack}
			onBopChangedConfirm={onBopChangedConfirm}
			onNavigateTransaction={(navigationPath: string) => {
				if (!transactionId) return;

				if (
					navigationPath.includes(paths().documents(transactionId)) &&
					!state.selectedBopOption
				) {
					setLogicState({ ...logicState, showUnselectedModal: true });
				} else {
					navigate(navigationPath);
				}
			}}
			onChangeCategory={onChangeCategory}
			onChangeFilterString={onChangeFilterString}
			onChangeSelected={onChangeSelected}
			onCloseUnselectedModal={onCloseUnselectedModal}
			accountManager={accountManager}
			onResumeLater={handleResumeLater}
			onSortByName={onSortByName}
			onSearch={onSearch}
			onNext={onNext}
			onPageChange={onPageChange}
		/>
	);
};
